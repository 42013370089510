.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: 0.69em;
  background-color: #008827;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

.refinement-bar {
  overflow: auto; }
  .refinement-bar ul {
    padding-left: 0; }
  @media (max-width: 768.98px) {
    .refinement-bar {
      background-color: #fff;
      box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
      display: none;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1; } }
  @media (max-width: 543.98px) {
    .refinement-bar {
      position: fixed;
      height: 100%; } }
  @media (min-width: 769px) {
    .refinement-bar {
      display: block !important; } }

.filter-header {
  margin-left: -15px;
  margin-right: -15px; }

.header-bar {
  background-color: #eee;
  border-bottom: 0.063em solid #eee; }
  .header-bar button.close {
    font-size: 1rem;
    font-weight: normal;
    opacity: 1; }
  .header-bar .fa.fa-close::before {
    font-size: 1.25em; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .header-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .header-bar {
      padding: 0.938em; } }

.refinements .header,
.refinements .values {
  padding: 0.313em 0; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .refinements .header li,
    .refinements .values li {
      display: inline-block; } }

.refinements .header {
  font-size: 1.125em;
  color: #444; }

.refinements ul {
  overflow: auto; }
  .refinements ul li {
    padding-left: 1px; }
    .refinements ul li button {
      border: none;
      color: var(--skin-link-color-1);
      background-color: transparent; }

.refinements li {
  list-style-type: none;
  padding-left: 0; }
  .refinements li.color-attribute {
    display: inline-block;
    padding-left: 0; }
    .refinements li.color-attribute button {
      padding: 0; }
  .refinements li.disabled {
    opacity: 0.5; }

@media (max-width: 543.98px) {
  .refinements {
    padding: 0; } }

.refinements .card-header h2 {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0; }

.secondary-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  overflow: auto; }
  @media (max-width: 768.98px) {
    .secondary-bar button.reset {
      float: right; } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .secondary-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .secondary-bar {
      padding: 0.938em; } }

.refinement-header {
  overflow: auto; }

.disabled {
  pointer-events: none; }

.grid-header,
.content-grid-header {
  margin-top: 1em;
  margin-bottom: 1em; }
  @media (max-width: 543.98px) {
    .grid-header .result-count,
    .content-grid-header .result-count {
      padding-bottom: 0.938em; }
    .grid-header .filter-results,
    .content-grid-header .filter-results {
      display: block;
      width: 100%; } }
  .grid-header select,
  .content-grid-header select {
    width: 100%; }

.result-count {
  font-size: 0.875rem; }

.refinement {
  margin-bottom: 1em; }
  @media (max-width: 768.98px) {
    .refinement.card {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-radius: 0;
      margin-bottom: 0; }
    .refinement .card-header {
      border-bottom: 0 none;
      padding: 0.25rem 1rem; }
    .refinement .card-body {
      padding: 0 1rem; } }
  .refinement a {
    text-decoration: none; }
  .refinement.refinement-category li {
    margin-left: 0.938em;
    display: block; }
  .refinement.refinement-category > ul > li {
    margin-left: 0; }

.search-banner {
  background-image: url("../images/search.jpg");
  background-position-y: 40%; }

.search-keywords {
  font-weight: bold; }

.filter-bar ul {
  padding-left: 15px; }

.filter-bar li {
  list-style: none;
  float: left;
  margin-left: 0.3125em;
  margin-right: 0.3125em; }
  .filter-bar li:first-child {
    margin-left: 0; }
  .filter-bar li.filter-value {
    border: 1px solid #ccc;
    padding: 0.3125em;
    border-radius: 3px;
    position: relative; }
    .filter-bar li.filter-value button {
      color: black;
      border: none;
      padding: 0 2rem 0 0.5rem; }
      .filter-bar li.filter-value button::after {
        content: "\f00d";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        position: absolute;
        right: 10px;
        top: 11px; }
  .filter-bar li.swatch-filter {
    font-size: 1.38em;
    position: relative;
    margin-top: 4px; }
    .filter-bar li.swatch-filter button {
      width: 100%;
      height: 100%;
      display: block;
      border: none; }
      .filter-bar li.swatch-filter button::before {
        content: "";
        background-color: white;
        width: 0.8em;
        height: 0.8em;
        border-radius: 0.4em;
        position: absolute;
        left: 0.5em;
        top: 0.5em; }
      .filter-bar li.swatch-filter button::after {
        content: "\f057";
        font: normal normal normal 14px/1 FontAwesome;
        display: inline;
        text-rendering: auto;
        position: absolute;
        left: 0.18em;
        top: 0.12em;
        font-size: 1.5em; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0.938em; }
    .product-grid .col-6 {
      padding-left: 5px;
      padding-right: 5px; } }

.search-tips {
  text-align: left;
  margin-top: 1.875rem; }

.category-item {
  margin-bottom: 0.938rem; }

.show-more,
.show-more-content {
  padding: 0.938em;
  clear: both; }

.swatch-mark.color-value[data-selected=true]::after {
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  left: 0.295em;
  position: absolute; }

.swatch-mark.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em; }

.search-nav {
  margin-bottom: 1em;
  border-bottom: 0.063em solid #ccc; }
  .search-nav .nav-tabs-wrapper {
    padding: 0; }
    @media (max-width: 543.98px) {
      .search-nav .nav-tabs-wrapper {
        width: 100%; } }
    .search-nav .nav-tabs-wrapper .nav-tabs {
      border-bottom: 0 none; }
  .search-nav .nav-link:focus {
    background-color: #f9f9f9; }

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-square-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-square-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-square-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-square-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-square-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: 0.69em;
  background-color: #008827;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-square-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-square-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-square-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-square-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-square-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-square-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-square-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-square-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous,
.swatch-square-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled,
  .swatch-square-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after,
  .swatch-square-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.swatch-square-miscellaneous {
  border-radius: 0;
  transform: unset; }
  .swatch-square-miscellaneous.selected::after {
    transform: unset; }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.compare-bar-wrapper {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s ease; }
  .compare-bar-wrapper .toggle-compare {
    position: absolute;
    right: 1.125rem;
    top: -2.1875rem;
    background-color: var(--color-grey1);
    border: 1px solid var(--color-hr-border-color);
    border-bottom: 0; }
    .compare-bar-wrapper .toggle-compare .hide-text {
      display: inline; }
    .compare-bar-wrapper .toggle-compare .show-text {
      display: none; }
  .compare-bar-wrapper.hidden {
    bottom: -8.25rem; }
    .compare-bar-wrapper.hidden .toggle-compare .hide-text {
      display: none; }
    .compare-bar-wrapper.hidden .toggle-compare .show-text {
      display: inline; }
  .compare-bar-wrapper .compare-bar {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .compare-bar-wrapper .product-slots {
    height: 6.25em; }
  .compare-bar-wrapper .slot {
    background-color: var(--color-white);
    height: 5em;
    margin: 0 auto;
    position: relative;
    width: 6.788em; }
    .compare-bar-wrapper .slot:not([data-pid]) {
      border: 1px dashed #ccc; }
    .compare-bar-wrapper .slot img {
      float: left;
      height: 100%;
      position: static; }
    .compare-bar-wrapper .slot i {
      color: #666;
      float: left;
      font-size: 1rem;
      position: relative;
      top: 50%;
      transform: translate(65%, -50%); }
    .compare-bar-wrapper .slot .close {
      background-color: #eee;
      height: 100%;
      width: 25%;
      opacity: 1; }
  .compare-bar-wrapper .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .compare-bar-wrapper .action .btn {
      width: 100%; }
  .compare-bar-wrapper .clear-all {
    margin-top: 0.625rem; }
  .compare-bar-wrapper .min-products-msg {
    color: #fff;
    font-size: 0.75em;
    line-height: 1.375rem;
    padding: 0.438rem; }

.product-comparison .back-to-results {
  margin: 1em 0; }

.product-comparison .product-header > .col {
  padding: 0; }

.product-tile .tile-body .compare {
  font-size: 0.875em; }

.table-striped-column td:nth-of-type(odd) {
  background-color: #eee; }

.breadcrumbs {
  border-bottom: 1px solid var(--color-grey3);
  margin-bottom: 1rem; }
  .breadcrumbs .breadcrumb {
    margin-bottom: 0; }

.search-nav {
  margin-bottom: 0;
  border-bottom: 0; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 15px;
    padding-right: 15px; } }

.secondary-bar.hide {
  display: none; }

.secondary-bar.show {
  display: block; }

@media (max-width: 991.98px) {
  .grid-header .result-count,
  .content-grid-header .result-count {
    padding-bottom: 0.938em; } }

.refinement-bar {
  transition: all 0.3s ease; }
  .refinement-bar.hide {
    width: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0; }

@media (min-width: 769px) {
  .refinements {
    margin-top: 0.625rem;
    padding-right: 1rem; } }

.refinements ul li {
  padding: 0; }
  .refinements ul li.color-attribute {
    display: list-item;
    margin: 0 0.5rem 0.125rem 0; }
  .refinements ul li.size-attribute {
    margin: 0 0.5rem 0.5rem 0; }
    .refinements ul li.size-attribute button {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0.25rem;
      font-size: 0.75rem; }

.refinement.card {
  margin-bottom: 1.5rem; }
  .refinement.card .card-header {
    padding: 0;
    border-bottom: 1px solid var(--color-grey3); }
    .refinement.card .card-header .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3125rem 0 0.5rem;
      line-height: 1; }
  .refinement.card .refinement-headline {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0; }
  .refinement.card .card-body {
    padding: 0.75rem 0 0; }
  .refinement.card .values {
    margin-bottom: 0;
    padding: 0; }
  .refinement.card.refinement-category li {
    margin-left: 0; }
    .refinement.card.refinement-category li.has-subs > button {
      text-transform: uppercase; }
    .refinement.card.refinement-category li.has-subs:before {
      font-family: 'FontAwesome';
      font-size: 0.875rem;
      content: "\F078";
      position: relative;
      top: -1px;
      line-height: 1; }
    .refinement.card.refinement-category li.selected > button {
      font-weight: 600;
      pointer-events: none; }
  .refinement.card.refinement-category ul ul {
    margin-left: 1rem; }

.filter-bar {
  margin-top: 0.875rem; }
  @media (min-width: 769px) {
    .filter-bar {
      margin-top: 0; } }
  .filter-bar ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin-bottom: 0; }
  .filter-bar li.swatch-filter {
    margin-top: 0; }
    .filter-bar li.swatch-filter button {
      display: flex;
      align-items: center;
      justify-content: center; }
      .filter-bar li.swatch-filter button:before, .filter-bar li.swatch-filter button:after {
        left: unset;
        top: unset; }
  .filter-bar li.filter-value {
    padding: 0.1875rem 0.3125rem; }
    .filter-bar li.filter-value button {
      display: flex;
      align-items: center; }
      .filter-bar li.filter-value button:after {
        top: unset; }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0; }
    .product-grid .col-6 {
      padding-left: 15px;
      padding-right: 15px; } }

.grid-tile-wrapper {
  margin-bottom: 2.5rem; }
  @media (min-width: 769px) {
    .grid-tile-wrapper {
      padding: 0.625rem 0.9375rem; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
[data-swatch-type="color-swatch-css"] {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: transparent;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  [data-swatch-type="color-swatch-css"]:before {
    content: '?';
    color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.swatch-circle-air,
.swatch-circle-air.large-swatch:after {
  background-color: #d7e6f6; }

.swatch-square-air {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d7e6f6;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-air.disabled {
    opacity: 0.2; }

.swatch-filter-air {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d7e6f6;
  border-radius: 0.69em;
  background-color: #d7e6f6;
  display: block;
  position: relative; }
  .swatch-filter-air.disabled {
    opacity: 0.2; }

.swatch-circle-amparoblue,
.swatch-circle-amparoblue.large-swatch:after {
  background-color: #2a4f92; }

.swatch-square-amparoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2a4f92;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-amparoblue.disabled {
    opacity: 0.2; }

.swatch-filter-amparoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2a4f92;
  border-radius: 0.69em;
  background-color: #2a4f92;
  display: block;
  position: relative; }
  .swatch-filter-amparoblue.disabled {
    opacity: 0.2; }

.swatch-circle-apple,
.swatch-circle-apple.large-swatch:after {
  background-color: #80ad2f; }

.swatch-square-apple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #80ad2f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-apple.disabled {
    opacity: 0.2; }

.swatch-filter-apple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #80ad2f;
  border-radius: 0.69em;
  background-color: #80ad2f;
  display: block;
  position: relative; }
  .swatch-filter-apple.disabled {
    opacity: 0.2; }

.swatch-circle-appleblue,
.swatch-circle-appleblue.large-swatch:after {
  background-color: #5bb4e5; }

.swatch-square-appleblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #5bb4e5;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-appleblue.disabled {
    opacity: 0.2; }

.swatch-filter-appleblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #5bb4e5;
  border-radius: 0.69em;
  background-color: #5bb4e5;
  display: block;
  position: relative; }
  .swatch-filter-appleblue.disabled {
    opacity: 0.2; }

.swatch-circle-applepink,
.swatch-circle-applepink.large-swatch:after {
  background-color: #e87ea6; }

.swatch-square-applepink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e87ea6;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-applepink.disabled {
    opacity: 0.2; }

.swatch-filter-applepink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e87ea6;
  border-radius: 0.69em;
  background-color: #e87ea6;
  display: block;
  position: relative; }
  .swatch-filter-applepink.disabled {
    opacity: 0.2; }

.swatch-circle-aqua,
.swatch-circle-aqua.large-swatch:after {
  background-color: #63bdd2; }

.swatch-square-aqua {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #63bdd2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-aqua.disabled {
    opacity: 0.2; }

.swatch-filter-aqua {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #63bdd2;
  border-radius: 0.69em;
  background-color: #63bdd2;
  display: block;
  position: relative; }
  .swatch-filter-aqua.disabled {
    opacity: 0.2; }

.swatch-circle-arborgreen,
.swatch-circle-arborgreen.large-swatch:after {
  background-color: #aab556; }

.swatch-square-arborgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #aab556;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-arborgreen.disabled {
    opacity: 0.2; }

.swatch-filter-arborgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #aab556;
  border-radius: 0.69em;
  background-color: #aab556;
  display: block;
  position: relative; }
  .swatch-filter-arborgreen.disabled {
    opacity: 0.2; }

.swatch-circle-ash,
.swatch-circle-ash.large-swatch:after {
  background-color: #3b3b3b; }

.swatch-square-ash {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3b3b3b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ash.disabled {
    opacity: 0.2; }

.swatch-filter-ash {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3b3b3b;
  border-radius: 0.69em;
  background-color: #3b3b3b;
  display: block;
  position: relative; }
  .swatch-filter-ash.disabled {
    opacity: 0.2; }

.swatch-circle-aubergine,
.swatch-circle-aubergine.large-swatch:after {
  background-color: #522955; }

.swatch-square-aubergine {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #522955;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-aubergine.disabled {
    opacity: 0.2; }

.swatch-filter-aubergine {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #522955;
  border-radius: 0.69em;
  background-color: #522955;
  display: block;
  position: relative; }
  .swatch-filter-aubergine.disabled {
    opacity: 0.2; }

.swatch-circle-azalea,
.swatch-circle-azalea.large-swatch:after {
  background-color: #991e66; }

.swatch-square-azalea {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #991e66;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-azalea.disabled {
    opacity: 0.2; }

.swatch-filter-azalea {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #991e66;
  border-radius: 0.69em;
  background-color: #991e66;
  display: block;
  position: relative; }
  .swatch-filter-azalea.disabled {
    opacity: 0.2; }

.swatch-circle-beatenpath,
.swatch-circle-beatenpath.large-swatch:after {
  background-color: #534a48; }

.swatch-square-beatenpath {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #534a48;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-beatenpath.disabled {
    opacity: 0.2; }

.swatch-filter-beatenpath {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #534a48;
  border-radius: 0.69em;
  background-color: #534a48;
  display: block;
  position: relative; }
  .swatch-filter-beatenpath.disabled {
    opacity: 0.2; }

.swatch-circle-beige,
.swatch-circle-beige.large-swatch:after {
  background-color: #cfc8b2; }

.swatch-square-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #cfc8b2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #cfc8b2;
  border-radius: 0.69em;
  background-color: #cfc8b2;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-berry,
.swatch-circle-berry.large-swatch:after {
  background-color: #bd4097; }

.swatch-square-berry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bd4097;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-berry.disabled {
    opacity: 0.2; }

.swatch-filter-berry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bd4097;
  border-radius: 0.69em;
  background-color: #bd4097;
  display: block;
  position: relative; }
  .swatch-filter-berry.disabled {
    opacity: 0.2; }

.swatch-circle-black,
.swatch-circle-black.large-swatch:after {
  background-color: black; }

.swatch-square-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: black;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: black;
  border-radius: 0.69em;
  background-color: black;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blackchrome,
.swatch-circle-blackchrome.large-swatch:after {
  background-color: #1f2325; }

.swatch-square-blackchrome {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1f2325;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blackchrome.disabled {
    opacity: 0.2; }

.swatch-filter-blackchrome {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1f2325;
  border-radius: 0.69em;
  background-color: #1f2325;
  display: block;
  position: relative; }
  .swatch-filter-blackchrome.disabled {
    opacity: 0.2; }

.swatch-circle-blackpearl,
.swatch-circle-blackpearl.large-swatch:after {
  background-color: #1f2325; }

.swatch-square-blackpearl {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1f2325;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blackpearl.disabled {
    opacity: 0.2; }

.swatch-filter-blackpearl {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1f2325;
  border-radius: 0.69em;
  background-color: #1f2325;
  display: block;
  position: relative; }
  .swatch-filter-blackpearl.disabled {
    opacity: 0.2; }

.swatch-circle-blacktop,
.swatch-circle-blacktop.large-swatch:after {
  background-color: #333235; }

.swatch-square-blacktop {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #333235;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blacktop.disabled {
    opacity: 0.2; }

.swatch-filter-blacktop {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #333235;
  border-radius: 0.69em;
  background-color: #333235;
  display: block;
  position: relative; }
  .swatch-filter-blacktop.disabled {
    opacity: 0.2; }

.swatch-circle-blue,
.swatch-circle-blue.large-swatch:after {
  background-color: #00549c; }

.swatch-square-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #00549c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #00549c;
  border-radius: 0.69em;
  background-color: #00549c;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-blueprint,
.swatch-circle-blueprint.large-swatch:after {
  background-color: #445587; }

.swatch-square-blueprint {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #445587;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blueprint.disabled {
    opacity: 0.2; }

.swatch-filter-blueprint {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #445587;
  border-radius: 0.69em;
  background-color: #445587;
  display: block;
  position: relative; }
  .swatch-filter-blueprint.disabled {
    opacity: 0.2; }

.swatch-circle-bluesail,
.swatch-circle-bluesail.large-swatch:after {
  background-color: #3e7dca; }

.swatch-square-bluesail {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3e7dca;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-bluesail.disabled {
    opacity: 0.2; }

.swatch-filter-bluesail {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3e7dca;
  border-radius: 0.69em;
  background-color: #3e7dca;
  display: block;
  position: relative; }
  .swatch-filter-bluesail.disabled {
    opacity: 0.2; }

.swatch-circle-blush,
.swatch-circle-blush.large-swatch:after {
  background-color: #ff5e3c; }

.swatch-square-blush {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ff5e3c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blush.disabled {
    opacity: 0.2; }

.swatch-filter-blush {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff5e3c;
  border-radius: 0.69em;
  background-color: #ff5e3c;
  display: block;
  position: relative; }
  .swatch-filter-blush.disabled {
    opacity: 0.2; }

.swatch-circle-blushpink,
.swatch-circle-blushpink.large-swatch:after {
  background-color: #fedfd8; }

.swatch-square-blushpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fedfd8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blushpink.disabled {
    opacity: 0.2; }

.swatch-filter-blushpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fedfd8;
  border-radius: 0.69em;
  background-color: #fedfd8;
  display: block;
  position: relative; }
  .swatch-filter-blushpink.disabled {
    opacity: 0.2; }

.swatch-circle-bone,
.swatch-circle-bone.large-swatch:after {
  background-color: #eae9e7; }

.swatch-square-bone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #eae9e7;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-bone.disabled {
    opacity: 0.2; }

.swatch-filter-bone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #eae9e7;
  border-radius: 0.69em;
  background-color: #eae9e7;
  display: block;
  position: relative; }
  .swatch-filter-bone.disabled {
    opacity: 0.2; }

.swatch-circle-brightblue,
.swatch-circle-brightblue.large-swatch:after {
  background-color: #2c5697; }

.swatch-square-brightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2c5697;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brightblue.disabled {
    opacity: 0.2; }

.swatch-filter-brightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2c5697;
  border-radius: 0.69em;
  background-color: #2c5697;
  display: block;
  position: relative; }
  .swatch-filter-brightblue.disabled {
    opacity: 0.2; }

.swatch-circle-brightpink,
.swatch-circle-brightpink.large-swatch:after {
  background-color: #d7387a; }

.swatch-square-brightpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d7387a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brightpink.disabled {
    opacity: 0.2; }

.swatch-filter-brightpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d7387a;
  border-radius: 0.69em;
  background-color: #d7387a;
  display: block;
  position: relative; }
  .swatch-filter-brightpink.disabled {
    opacity: 0.2; }

.swatch-circle-brightred,
.swatch-circle-brightred.large-swatch:after {
  background-color: #d01e28; }

.swatch-square-brightred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d01e28;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brightred.disabled {
    opacity: 0.2; }

.swatch-filter-brightred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d01e28;
  border-radius: 0.69em;
  background-color: #d01e28;
  display: block;
  position: relative; }
  .swatch-filter-brightred.disabled {
    opacity: 0.2; }

.swatch-circle-brown,
.swatch-circle-brown.large-swatch:after {
  background-color: #543024; }

.swatch-square-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #543024;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #543024;
  border-radius: 0.69em;
  background-color: #543024;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-bubblegum,
.swatch-circle-bubblegum.large-swatch:after {
  background-color: #f66a8e; }

.swatch-square-bubblegum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f66a8e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-bubblegum.disabled {
    opacity: 0.2; }

.swatch-filter-bubblegum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f66a8e;
  border-radius: 0.69em;
  background-color: #f66a8e;
  display: block;
  position: relative; }
  .swatch-filter-bubblegum.disabled {
    opacity: 0.2; }

.swatch-circle-burgandy,
.swatch-circle-burgandy.large-swatch:after {
  background-color: #8b3744; }

.swatch-square-burgandy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8b3744;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-burgandy.disabled {
    opacity: 0.2; }

.swatch-filter-burgandy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8b3744;
  border-radius: 0.69em;
  background-color: #8b3744;
  display: block;
  position: relative; }
  .swatch-filter-burgandy.disabled {
    opacity: 0.2; }

.swatch-circle-burntorange,
.swatch-circle-burntorange.large-swatch:after {
  background-color: #f07515; }

.swatch-square-burntorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f07515;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-burntorange.disabled {
    opacity: 0.2; }

.swatch-filter-burntorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f07515;
  border-radius: 0.69em;
  background-color: #f07515;
  display: block;
  position: relative; }
  .swatch-filter-burntorange.disabled {
    opacity: 0.2; }

.swatch-circle-cabernet,
.swatch-circle-cabernet.large-swatch:after {
  background-color: #c84c4a; }

.swatch-square-cabernet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c84c4a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cabernet.disabled {
    opacity: 0.2; }

.swatch-filter-cabernet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c84c4a;
  border-radius: 0.69em;
  background-color: #c84c4a;
  display: block;
  position: relative; }
  .swatch-filter-cabernet.disabled {
    opacity: 0.2; }

.swatch-circle-cameopink,
.swatch-circle-cameopink.large-swatch:after {
  background-color: #deaec2; }

.swatch-square-cameopink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #deaec2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cameopink.disabled {
    opacity: 0.2; }

.swatch-filter-cameopink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #deaec2;
  border-radius: 0.69em;
  background-color: #deaec2;
  display: block;
  position: relative; }
  .swatch-filter-cameopink.disabled {
    opacity: 0.2; }

.swatch-circle-candyred,
.swatch-circle-candyred.large-swatch:after {
  background-color: #c10336; }

.swatch-square-candyred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c10336;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-candyred.disabled {
    opacity: 0.2; }

.swatch-filter-candyred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c10336;
  border-radius: 0.69em;
  background-color: #c10336;
  display: block;
  position: relative; }
  .swatch-filter-candyred.disabled {
    opacity: 0.2; }

.swatch-circle-canyon,
.swatch-circle-canyon.large-swatch:after {
  background-color: #7e4e33; }

.swatch-square-canyon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #7e4e33;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-canyon.disabled {
    opacity: 0.2; }

.swatch-filter-canyon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #7e4e33;
  border-radius: 0.69em;
  background-color: #7e4e33;
  display: block;
  position: relative; }
  .swatch-filter-canyon.disabled {
    opacity: 0.2; }

.swatch-circle-carbon,
.swatch-circle-carbon.large-swatch:after {
  background-color: #3c3744; }

.swatch-square-carbon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3c3744;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-carbon.disabled {
    opacity: 0.2; }

.swatch-filter-carbon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3c3744;
  border-radius: 0.69em;
  background-color: #3c3744;
  display: block;
  position: relative; }
  .swatch-filter-carbon.disabled {
    opacity: 0.2; }

.swatch-circle-carmine,
.swatch-circle-carmine.large-swatch:after {
  background-color: #be0f34; }

.swatch-square-carmine {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #be0f34;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-carmine.disabled {
    opacity: 0.2; }

.swatch-filter-carmine {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #be0f34;
  border-radius: 0.69em;
  background-color: #be0f34;
  display: block;
  position: relative; }
  .swatch-filter-carmine.disabled {
    opacity: 0.2; }

.swatch-circle-carnation,
.swatch-circle-carnation.large-swatch:after {
  background-color: #ffa1a3; }

.swatch-square-carnation {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ffa1a3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-carnation.disabled {
    opacity: 0.2; }

.swatch-filter-carnation {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa1a3;
  border-radius: 0.69em;
  background-color: #ffa1a3;
  display: block;
  position: relative; }
  .swatch-filter-carnation.disabled {
    opacity: 0.2; }

.swatch-circle-caviar,
.swatch-circle-caviar.large-swatch:after {
  background-color: #333235; }

.swatch-square-caviar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #333235;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-caviar.disabled {
    opacity: 0.2; }

.swatch-filter-caviar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #333235;
  border-radius: 0.69em;
  background-color: #333235;
  display: block;
  position: relative; }
  .swatch-filter-caviar.disabled {
    opacity: 0.2; }

.swatch-circle-cedar,
.swatch-circle-cedar.large-swatch:after {
  background-color: #b28d73; }

.swatch-square-cedar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #b28d73;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cedar.disabled {
    opacity: 0.2; }

.swatch-filter-cedar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #b28d73;
  border-radius: 0.69em;
  background-color: #b28d73;
  display: block;
  position: relative; }
  .swatch-filter-cedar.disabled {
    opacity: 0.2; }

.swatch-circle-celadon,
.swatch-circle-celadon.large-swatch:after {
  background-color: #cbda89; }

.swatch-square-celadon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #cbda89;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-celadon.disabled {
    opacity: 0.2; }

.swatch-filter-celadon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #cbda89;
  border-radius: 0.69em;
  background-color: #cbda89;
  display: block;
  position: relative; }
  .swatch-filter-celadon.disabled {
    opacity: 0.2; }

.swatch-circle-charcoal,
.swatch-circle-charcoal.large-swatch:after {
  background-color: #534f50; }

.swatch-square-charcoal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #534f50;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-charcoal.disabled {
    opacity: 0.2; }

.swatch-filter-charcoal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #534f50;
  border-radius: 0.69em;
  background-color: #534f50;
  display: block;
  position: relative; }
  .swatch-filter-charcoal.disabled {
    opacity: 0.2; }

.swatch-circle-cherryblossom,
.swatch-circle-cherryblossom.large-swatch:after {
  background-color: #f9a8a3; }

.swatch-square-cherryblossom {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f9a8a3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cherryblossom.disabled {
    opacity: 0.2; }

.swatch-filter-cherryblossom {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f9a8a3;
  border-radius: 0.69em;
  background-color: #f9a8a3;
  display: block;
  position: relative; }
  .swatch-filter-cherryblossom.disabled {
    opacity: 0.2; }

.swatch-circle-chilipepper,
.swatch-circle-chilipepper.large-swatch:after {
  background-color: #9b2242; }

.swatch-square-chilipepper {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #9b2242;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-chilipepper.disabled {
    opacity: 0.2; }

.swatch-filter-chilipepper {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #9b2242;
  border-radius: 0.69em;
  background-color: #9b2242;
  display: block;
  position: relative; }
  .swatch-filter-chilipepper.disabled {
    opacity: 0.2; }

.swatch-circle-chocolate,
.swatch-circle-chocolate.large-swatch:after {
  background-color: #413231; }

.swatch-square-chocolate {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #413231;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-chocolate.disabled {
    opacity: 0.2; }

.swatch-filter-chocolate {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #413231;
  border-radius: 0.69em;
  background-color: #413231;
  display: block;
  position: relative; }
  .swatch-filter-chocolate.disabled {
    opacity: 0.2; }

.swatch-circle-citron,
.swatch-circle-citron.large-swatch:after {
  background-color: #e2a21c; }

.swatch-square-citron {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e2a21c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-citron.disabled {
    opacity: 0.2; }

.swatch-filter-citron {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e2a21c;
  border-radius: 0.69em;
  background-color: #e2a21c;
  display: block;
  position: relative; }
  .swatch-filter-citron.disabled {
    opacity: 0.2; }

.swatch-circle-citrus,
.swatch-circle-citrus.large-swatch:after {
  background-color: #fcdc41; }

.swatch-square-citrus {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fcdc41;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-citrus.disabled {
    opacity: 0.2; }

.swatch-filter-citrus {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fcdc41;
  border-radius: 0.69em;
  background-color: #fcdc41;
  display: block;
  position: relative; }
  .swatch-filter-citrus.disabled {
    opacity: 0.2; }

.swatch-circle-citrusyellow,
.swatch-circle-citrusyellow.large-swatch:after {
  background-color: #e1d761; }

.swatch-square-citrusyellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e1d761;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-citrusyellow.disabled {
    opacity: 0.2; }

.swatch-filter-citrusyellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e1d761;
  border-radius: 0.69em;
  background-color: #e1d761;
  display: block;
  position: relative; }
  .swatch-filter-citrusyellow.disabled {
    opacity: 0.2; }

.swatch-circle-civicblue,
.swatch-circle-civicblue.large-swatch:after {
  background-color: #004e94; }

.swatch-square-civicblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #004e94;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-civicblue.disabled {
    opacity: 0.2; }

.swatch-filter-civicblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #004e94;
  border-radius: 0.69em;
  background-color: #004e94;
  display: block;
  position: relative; }
  .swatch-filter-civicblue.disabled {
    opacity: 0.2; }

.swatch-circle-clear,
.swatch-circle-clear.large-swatch:after {
  background-color: #fefefe; }

.swatch-square-clear {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fefefe;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-clear.disabled {
    opacity: 0.2; }

.swatch-filter-clear {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fefefe;
  border-radius: 0.69em;
  background-color: #fefefe;
  display: block;
  position: relative; }
  .swatch-filter-clear.disabled {
    opacity: 0.2; }

.swatch-circle-cloud,
.swatch-circle-cloud.large-swatch:after {
  background-color: #fafbfc; }

.swatch-square-cloud {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fafbfc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cloud.disabled {
    opacity: 0.2; }

.swatch-filter-cloud {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fafbfc;
  border-radius: 0.69em;
  background-color: #fafbfc;
  display: block;
  position: relative; }
  .swatch-filter-cloud.disabled {
    opacity: 0.2; }

.swatch-circle-cocktail,
.swatch-circle-cocktail.large-swatch:after {
  background-color: #da5a90; }

.swatch-square-cocktail {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #da5a90;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cocktail.disabled {
    opacity: 0.2; }

.swatch-filter-cocktail {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #da5a90;
  border-radius: 0.69em;
  background-color: #da5a90;
  display: block;
  position: relative; }
  .swatch-filter-cocktail.disabled {
    opacity: 0.2; }

.swatch-circle-coffeebean,
.swatch-circle-coffeebean.large-swatch:after {
  background-color: #4e372b; }

.swatch-square-coffeebean {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4e372b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-coffeebean.disabled {
    opacity: 0.2; }

.swatch-filter-coffeebean {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4e372b;
  border-radius: 0.69em;
  background-color: #4e372b;
  display: block;
  position: relative; }
  .swatch-filter-coffeebean.disabled {
    opacity: 0.2; }

.swatch-circle-copper,
.swatch-circle-copper.large-swatch:after {
  background-color: #935d32; }

.swatch-square-copper {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #935d32;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-copper.disabled {
    opacity: 0.2; }

.swatch-filter-copper {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #935d32;
  border-radius: 0.69em;
  background-color: #935d32;
  display: block;
  position: relative; }
  .swatch-filter-copper.disabled {
    opacity: 0.2; }

.swatch-circle-copperchrome,
.swatch-circle-copperchrome.large-swatch:after {
  background-color: #6e5a51; }

.swatch-square-copperchrome {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6e5a51;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-copperchrome.disabled {
    opacity: 0.2; }

.swatch-filter-copperchrome {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6e5a51;
  border-radius: 0.69em;
  background-color: #6e5a51;
  display: block;
  position: relative; }
  .swatch-filter-copperchrome.disabled {
    opacity: 0.2; }

.swatch-circle-coral,
.swatch-circle-coral.large-swatch:after {
  background-color: #e91c6a; }

.swatch-square-coral {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e91c6a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-coral.disabled {
    opacity: 0.2; }

.swatch-filter-coral {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e91c6a;
  border-radius: 0.69em;
  background-color: #e91c6a;
  display: block;
  position: relative; }
  .swatch-filter-coral.disabled {
    opacity: 0.2; }

.swatch-circle-coralpink,
.swatch-circle-coralpink.large-swatch:after {
  background-color: #e91c6a; }

.swatch-square-coralpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e91c6a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-coralpink.disabled {
    opacity: 0.2; }

.swatch-filter-coralpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e91c6a;
  border-radius: 0.69em;
  background-color: #e91c6a;
  display: block;
  position: relative; }
  .swatch-filter-coralpink.disabled {
    opacity: 0.2; }

.swatch-circle-cornerstone,
.swatch-circle-cornerstone.large-swatch:after {
  background-color: #696c71; }

.swatch-square-cornerstone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #696c71;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cornerstone.disabled {
    opacity: 0.2; }

.swatch-filter-cornerstone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #696c71;
  border-radius: 0.69em;
  background-color: #696c71;
  display: block;
  position: relative; }
  .swatch-filter-cornerstone.disabled {
    opacity: 0.2; }

.swatch-circle-cream,
.swatch-circle-cream.large-swatch:after {
  background-color: #ded4c4; }

.swatch-square-cream {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ded4c4;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cream.disabled {
    opacity: 0.2; }

.swatch-filter-cream {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ded4c4;
  border-radius: 0.69em;
  background-color: #ded4c4;
  display: block;
  position: relative; }
  .swatch-filter-cream.disabled {
    opacity: 0.2; }

.swatch-circle-cyan,
.swatch-circle-cyan.large-swatch:after {
  background-color: #258ec1; }

.swatch-square-cyan {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #258ec1;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-cyan.disabled {
    opacity: 0.2; }

.swatch-filter-cyan {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #258ec1;
  border-radius: 0.69em;
  background-color: #258ec1;
  display: block;
  position: relative; }
  .swatch-filter-cyan.disabled {
    opacity: 0.2; }

.swatch-circle-dahlia,
.swatch-circle-dahlia.large-swatch:after {
  background-color: #7c4386; }

.swatch-square-dahlia {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #7c4386;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-dahlia.disabled {
    opacity: 0.2; }

.swatch-filter-dahlia {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #7c4386;
  border-radius: 0.69em;
  background-color: #7c4386;
  display: block;
  position: relative; }
  .swatch-filter-dahlia.disabled {
    opacity: 0.2; }

.swatch-circle-darkblue,
.swatch-circle-darkblue.large-swatch:after {
  background-color: #3c4564; }

.swatch-square-darkblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3c4564;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darkblue.disabled {
    opacity: 0.2; }

.swatch-filter-darkblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3c4564;
  border-radius: 0.69em;
  background-color: #3c4564;
  display: block;
  position: relative; }
  .swatch-filter-darkblue.disabled {
    opacity: 0.2; }

.swatch-circle-darkbrown,
.swatch-circle-darkbrown.large-swatch:after {
  background-color: #413231; }

.swatch-square-darkbrown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #413231;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darkbrown.disabled {
    opacity: 0.2; }

.swatch-filter-darkbrown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #413231;
  border-radius: 0.69em;
  background-color: #413231;
  display: block;
  position: relative; }
  .swatch-filter-darkbrown.disabled {
    opacity: 0.2; }

.swatch-circle-darkgray,
.swatch-circle-darkgray.large-swatch:after {
  background-color: #524d53; }

.swatch-square-darkgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #524d53;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darkgray.disabled {
    opacity: 0.2; }

.swatch-filter-darkgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #524d53;
  border-radius: 0.69em;
  background-color: #524d53;
  display: block;
  position: relative; }
  .swatch-filter-darkgray.disabled {
    opacity: 0.2; }

.swatch-circle-darkgreen,
.swatch-circle-darkgreen.large-swatch:after {
  background-color: #006e33; }

.swatch-square-darkgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #006e33;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darkgreen.disabled {
    opacity: 0.2; }

.swatch-filter-darkgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #006e33;
  border-radius: 0.69em;
  background-color: #006e33;
  display: block;
  position: relative; }
  .swatch-filter-darkgreen.disabled {
    opacity: 0.2; }

.swatch-circle-darklines,
.swatch-circle-darklines.large-swatch:after {
  background-color: #524d53; }

.swatch-square-darklines {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #524d53;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darklines.disabled {
    opacity: 0.2; }

.swatch-filter-darklines {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #524d53;
  border-radius: 0.69em;
  background-color: #524d53;
  display: block;
  position: relative; }
  .swatch-filter-darklines.disabled {
    opacity: 0.2; }

.swatch-circle-darkpurple,
.swatch-circle-darkpurple.large-swatch:after {
  background-color: #503265; }

.swatch-square-darkpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #503265;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darkpurple.disabled {
    opacity: 0.2; }

.swatch-filter-darkpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #503265;
  border-radius: 0.69em;
  background-color: #503265;
  display: block;
  position: relative; }
  .swatch-filter-darkpurple.disabled {
    opacity: 0.2; }

.swatch-circle-darksky,
.swatch-circle-darksky.large-swatch:after {
  background-color: #000000; }

.swatch-square-darksky {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #000000;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-darksky.disabled {
    opacity: 0.2; }

.swatch-filter-darksky {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000000;
  border-radius: 0.69em;
  background-color: #000000;
  display: block;
  position: relative; }
  .swatch-filter-darksky.disabled {
    opacity: 0.2; }

.swatch-circle-dayglow,
.swatch-circle-dayglow.large-swatch:after {
  background-color: #ff14a3; }

.swatch-square-dayglow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ff14a3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-dayglow.disabled {
    opacity: 0.2; }

.swatch-filter-dayglow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff14a3;
  border-radius: 0.69em;
  background-color: #ff14a3;
  display: block;
  position: relative; }
  .swatch-filter-dayglow.disabled {
    opacity: 0.2; }

.swatch-circle-deepsea,
.swatch-circle-deepsea.large-swatch:after {
  background-color: #13284c; }

.swatch-square-deepsea {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #13284c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-deepsea.disabled {
    opacity: 0.2; }

.swatch-filter-deepsea {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #13284c;
  border-radius: 0.69em;
  background-color: #13284c;
  display: block;
  position: relative; }
  .swatch-filter-deepsea.disabled {
    opacity: 0.2; }

.swatch-circle-denim,
.swatch-circle-denim.large-swatch:after {
  background-color: #7590a8; }

.swatch-square-denim {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #7590a8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-denim.disabled {
    opacity: 0.2; }

.swatch-filter-denim {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #7590a8;
  border-radius: 0.69em;
  background-color: #7590a8;
  display: block;
  position: relative; }
  .swatch-filter-denim.disabled {
    opacity: 0.2; }

.swatch-circle-desertpink,
.swatch-circle-desertpink.large-swatch:after {
  background-color: #e6cecb; }

.swatch-square-desertpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e6cecb;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-desertpink.disabled {
    opacity: 0.2; }

.swatch-filter-desertpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e6cecb;
  border-radius: 0.69em;
  background-color: #e6cecb;
  display: block;
  position: relative; }
  .swatch-filter-desertpink.disabled {
    opacity: 0.2; }

.swatch-circle-dove,
.swatch-circle-dove.large-swatch:after {
  background-color: #c2c0c3; }

.swatch-square-dove {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c2c0c3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-dove.disabled {
    opacity: 0.2; }

.swatch-filter-dove {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c2c0c3;
  border-radius: 0.69em;
  background-color: #c2c0c3;
  display: block;
  position: relative; }
  .swatch-filter-dove.disabled {
    opacity: 0.2; }

.swatch-circle-dune,
.swatch-circle-dune.large-swatch:after {
  background-color: #69817e; }

.swatch-square-dune {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #69817e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-dune.disabled {
    opacity: 0.2; }

.swatch-filter-dune {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #69817e;
  border-radius: 0.69em;
  background-color: #69817e;
  display: block;
  position: relative; }
  .swatch-filter-dune.disabled {
    opacity: 0.2; }

.swatch-circle-ecru,
.swatch-circle-ecru.large-swatch:after {
  background-color: #c0b29e; }

.swatch-square-ecru {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c0b29e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ecru.disabled {
    opacity: 0.2; }

.swatch-filter-ecru {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c0b29e;
  border-radius: 0.69em;
  background-color: #c0b29e;
  display: block;
  position: relative; }
  .swatch-filter-ecru.disabled {
    opacity: 0.2; }

.swatch-circle-electric,
.swatch-circle-electric.large-swatch:after {
  background-color: #ffe100; }

.swatch-square-electric {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ffe100;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-electric.disabled {
    opacity: 0.2; }

.swatch-filter-electric {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffe100;
  border-radius: 0.69em;
  background-color: #ffe100;
  display: block;
  position: relative; }
  .swatch-filter-electric.disabled {
    opacity: 0.2; }

.swatch-circle-emerald,
.swatch-circle-emerald.large-swatch:after {
  background-color: #5ba590; }

.swatch-square-emerald {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #5ba590;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-emerald.disabled {
    opacity: 0.2; }

.swatch-filter-emerald {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #5ba590;
  border-radius: 0.69em;
  background-color: #5ba590;
  display: block;
  position: relative; }
  .swatch-filter-emerald.disabled {
    opacity: 0.2; }

.swatch-circle-equinox,
.swatch-circle-equinox.large-swatch:after {
  background-color: #2b1814; }

.swatch-square-equinox {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2b1814;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-equinox.disabled {
    opacity: 0.2; }

.swatch-filter-equinox {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2b1814;
  border-radius: 0.69em;
  background-color: #2b1814;
  display: block;
  position: relative; }
  .swatch-filter-equinox.disabled {
    opacity: 0.2; }

.swatch-circle-espresso,
.swatch-circle-espresso.large-swatch:after {
  background-color: #3f312e; }

.swatch-square-espresso {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3f312e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-espresso.disabled {
    opacity: 0.2; }

.swatch-filter-espresso {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3f312e;
  border-radius: 0.69em;
  background-color: #3f312e;
  display: block;
  position: relative; }
  .swatch-filter-espresso.disabled {
    opacity: 0.2; }

.swatch-circle-fade,
.swatch-circle-fade.large-swatch:after {
  background-color: #dddfd9; }

.swatch-square-fade {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #dddfd9;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-fade.disabled {
    opacity: 0.2; }

.swatch-filter-fade {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #dddfd9;
  border-radius: 0.69em;
  background-color: #dddfd9;
  display: block;
  position: relative; }
  .swatch-filter-fade.disabled {
    opacity: 0.2; }

.swatch-circle-fiesta,
.swatch-circle-fiesta.large-swatch:after {
  background-color: #dc291e; }

.swatch-square-fiesta {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #dc291e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-fiesta.disabled {
    opacity: 0.2; }

.swatch-filter-fiesta {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #dc291e;
  border-radius: 0.69em;
  background-color: #dc291e;
  display: block;
  position: relative; }
  .swatch-filter-fiesta.disabled {
    opacity: 0.2; }

.swatch-circle-flamingopink,
.swatch-circle-flamingopink.large-swatch:after {
  background-color: #931d3f; }

.swatch-square-flamingopink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #931d3f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-flamingopink.disabled {
    opacity: 0.2; }

.swatch-filter-flamingopink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #931d3f;
  border-radius: 0.69em;
  background-color: #931d3f;
  display: block;
  position: relative; }
  .swatch-filter-flamingopink.disabled {
    opacity: 0.2; }

.swatch-circle-flash,
.swatch-circle-flash.large-swatch:after {
  background-color: #c2f03f; }

.swatch-square-flash {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c2f03f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-flash.disabled {
    opacity: 0.2; }

.swatch-filter-flash {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c2f03f;
  border-radius: 0.69em;
  background-color: #c2f03f;
  display: block;
  position: relative; }
  .swatch-filter-flash.disabled {
    opacity: 0.2; }

.swatch-circle-flintgray,
.swatch-circle-flintgray.large-swatch:after {
  background-color: #6c6c6c; }

.swatch-square-flintgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6c6c6c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-flintgray.disabled {
    opacity: 0.2; }

.swatch-filter-flintgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6c6c6c;
  border-radius: 0.69em;
  background-color: #6c6c6c;
  display: block;
  position: relative; }
  .swatch-filter-flintgray.disabled {
    opacity: 0.2; }

.swatch-circle-fountainblue,
.swatch-circle-fountainblue.large-swatch:after {
  background-color: #00b3aa; }

.swatch-square-fountainblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #00b3aa;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-fountainblue.disabled {
    opacity: 0.2; }

.swatch-filter-fountainblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #00b3aa;
  border-radius: 0.69em;
  background-color: #00b3aa;
  display: block;
  position: relative; }
  .swatch-filter-fountainblue.disabled {
    opacity: 0.2; }

.swatch-circle-fresh,
.swatch-circle-fresh.large-swatch:after {
  background-color: #8fd400; }

.swatch-square-fresh {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8fd400;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-fresh.disabled {
    opacity: 0.2; }

.swatch-filter-fresh {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8fd400;
  border-radius: 0.69em;
  background-color: #8fd400;
  display: block;
  position: relative; }
  .swatch-filter-fresh.disabled {
    opacity: 0.2; }

.swatch-circle-frostwhite,
.swatch-circle-frostwhite.large-swatch:after {
  background-color: #dadcdb; }

.swatch-square-frostwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #dadcdb;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-frostwhite.disabled {
    opacity: 0.2; }

.swatch-filter-frostwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #dadcdb;
  border-radius: 0.69em;
  background-color: #dadcdb;
  display: block;
  position: relative; }
  .swatch-filter-frostwhite.disabled {
    opacity: 0.2; }

.swatch-circle-fuchsia,
.swatch-circle-fuchsia.large-swatch:after {
  background-color: #af467f; }

.swatch-square-fuchsia {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #af467f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-fuchsia.disabled {
    opacity: 0.2; }

.swatch-filter-fuchsia {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #af467f;
  border-radius: 0.69em;
  background-color: #af467f;
  display: block;
  position: relative; }
  .swatch-filter-fuchsia.disabled {
    opacity: 0.2; }

.swatch-circle-garnet,
.swatch-circle-garnet.large-swatch:after {
  background-color: #5a2c34; }

.swatch-square-garnet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #5a2c34;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-garnet.disabled {
    opacity: 0.2; }

.swatch-filter-garnet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #5a2c34;
  border-radius: 0.69em;
  background-color: #5a2c34;
  display: block;
  position: relative; }
  .swatch-filter-garnet.disabled {
    opacity: 0.2; }

.swatch-circle-glow,
.swatch-circle-glow.large-swatch:after {
  background-color: #bed600; }

.swatch-square-glow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bed600;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-glow.disabled {
    opacity: 0.2; }

.swatch-filter-glow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bed600;
  border-radius: 0.69em;
  background-color: #bed600;
  display: block;
  position: relative; }
  .swatch-filter-glow.disabled {
    opacity: 0.2; }

.swatch-circle-gold,
.swatch-circle-gold.large-swatch:after {
  background-color: #efe2d2; }

.swatch-square-gold {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #efe2d2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-gold.disabled {
    opacity: 0.2; }

.swatch-filter-gold {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #efe2d2;
  border-radius: 0.69em;
  background-color: #efe2d2;
  display: block;
  position: relative; }
  .swatch-filter-gold.disabled {
    opacity: 0.2; }

.swatch-circle-goldengarden,
.swatch-circle-goldengarden.large-swatch:after {
  background-color: #ffb100; }

.swatch-square-goldengarden {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ffb100;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-goldengarden.disabled {
    opacity: 0.2; }

.swatch-filter-goldengarden {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffb100;
  border-radius: 0.69em;
  background-color: #ffb100;
  display: block;
  position: relative; }
  .swatch-filter-goldengarden.disabled {
    opacity: 0.2; }

.swatch-circle-grape,
.swatch-circle-grape.large-swatch:after {
  background-color: #3d1843; }

.swatch-square-grape {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3d1843;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grape.disabled {
    opacity: 0.2; }

.swatch-filter-grape {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3d1843;
  border-radius: 0.69em;
  background-color: #3d1843;
  display: block;
  position: relative; }
  .swatch-filter-grape.disabled {
    opacity: 0.2; }

.swatch-circle-grapefruit,
.swatch-circle-grapefruit.large-swatch:after {
  background-color: #f8799b; }

.swatch-square-grapefruit {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f8799b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grapefruit.disabled {
    opacity: 0.2; }

.swatch-filter-grapefruit {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f8799b;
  border-radius: 0.69em;
  background-color: #f8799b;
  display: block;
  position: relative; }
  .swatch-filter-grapefruit.disabled {
    opacity: 0.2; }

.swatch-circle-graphite,
.swatch-circle-graphite.large-swatch:after {
  background-color: #252525; }

.swatch-square-graphite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #252525;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-graphite.disabled {
    opacity: 0.2; }

.swatch-filter-graphite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #252525;
  border-radius: 0.69em;
  background-color: #252525;
  display: block;
  position: relative; }
  .swatch-filter-graphite.disabled {
    opacity: 0.2; }

.swatch-circle-grassgreen,
.swatch-circle-grassgreen.large-swatch:after {
  background-color: #bed443; }

.swatch-square-grassgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bed443;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grassgreen.disabled {
    opacity: 0.2; }

.swatch-filter-grassgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bed443;
  border-radius: 0.69em;
  background-color: #bed443;
  display: block;
  position: relative; }
  .swatch-filter-grassgreen.disabled {
    opacity: 0.2; }

.swatch-circle-gravel,
.swatch-circle-gravel.large-swatch:after {
  background-color: #52565b; }

.swatch-square-gravel {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #52565b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-gravel.disabled {
    opacity: 0.2; }

.swatch-filter-gravel {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #52565b;
  border-radius: 0.69em;
  background-color: #52565b;
  display: block;
  position: relative; }
  .swatch-filter-gravel.disabled {
    opacity: 0.2; }

.swatch-circle-gray,
.swatch-circle-gray.large-swatch:after {
  background-color: #626567; }

.swatch-square-gray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #626567;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-gray.disabled {
    opacity: 0.2; }

.swatch-filter-gray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #626567;
  border-radius: 0.69em;
  background-color: #626567;
  display: block;
  position: relative; }
  .swatch-filter-gray.disabled {
    opacity: 0.2; }

.swatch-circle-graywoven,
.swatch-circle-graywoven.large-swatch:after {
  background-color: #848486; }

.swatch-square-graywoven {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #848486;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-graywoven.disabled {
    opacity: 0.2; }

.swatch-filter-graywoven {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #848486;
  border-radius: 0.69em;
  background-color: #848486;
  display: block;
  position: relative; }
  .swatch-filter-graywoven.disabled {
    opacity: 0.2; }

.swatch-circle-green,
.swatch-circle-green.large-swatch:after {
  background-color: #6FFB38; }

.swatch-square-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6FFB38;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6FFB38;
  border-radius: 0.69em;
  background-color: #6FFB38;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-green2,
.swatch-circle-green2.large-swatch:after {
  background-color: #8aaf6c; }

.swatch-square-green2 {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8aaf6c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-green2.disabled {
    opacity: 0.2; }

.swatch-filter-green2 {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8aaf6c;
  border-radius: 0.69em;
  background-color: #8aaf6c;
  display: block;
  position: relative; }
  .swatch-filter-green2.disabled {
    opacity: 0.2; }

.swatch-circle-grey,
.swatch-circle-grey.large-swatch:after {
  background-color: #626567; }

.swatch-square-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #626567;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #626567;
  border-radius: 0.69em;
  background-color: #626567;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-hazard,
.swatch-circle-hazard.large-swatch:after {
  background-color: #ff545b; }

.swatch-square-hazard {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ff545b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-hazard.disabled {
    opacity: 0.2; }

.swatch-filter-hazard {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff545b;
  border-radius: 0.69em;
  background-color: #ff545b;
  display: block;
  position: relative; }
  .swatch-filter-hazard.disabled {
    opacity: 0.2; }

.swatch-circle-hotpink,
.swatch-circle-hotpink.large-swatch:after {
  background-color: #ca2d7b; }

.swatch-square-hotpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ca2d7b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-hotpink.disabled {
    opacity: 0.2; }

.swatch-filter-hotpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ca2d7b;
  border-radius: 0.69em;
  background-color: #ca2d7b;
  display: block;
  position: relative; }
  .swatch-filter-hotpink.disabled {
    opacity: 0.2; }

.swatch-circle-hyperblue,
.swatch-circle-hyperblue.large-swatch:after {
  background-color: #0291da; }

.swatch-square-hyperblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0291da;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-hyperblue.disabled {
    opacity: 0.2; }

.swatch-filter-hyperblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0291da;
  border-radius: 0.69em;
  background-color: #0291da;
  display: block;
  position: relative; }
  .swatch-filter-hyperblue.disabled {
    opacity: 0.2; }

.swatch-circle-ice,
.swatch-circle-ice.large-swatch:after {
  background-color: #a4b7c8; }

.swatch-square-ice {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a4b7c8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ice.disabled {
    opacity: 0.2; }

.swatch-filter-ice {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a4b7c8;
  border-radius: 0.69em;
  background-color: #a4b7c8;
  display: block;
  position: relative; }
  .swatch-filter-ice.disabled {
    opacity: 0.2; }

.swatch-circle-indigo,
.swatch-circle-indigo.large-swatch:after {
  background-color: #274374; }

.swatch-square-indigo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #274374;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-indigo.disabled {
    opacity: 0.2; }

.swatch-filter-indigo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #274374;
  border-radius: 0.69em;
  background-color: #274374;
  display: block;
  position: relative; }
  .swatch-filter-indigo.disabled {
    opacity: 0.2; }

.swatch-circle-indigoblue,
.swatch-circle-indigoblue.large-swatch:after {
  background-color: #233a7d; }

.swatch-square-indigoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #233a7d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-indigoblue.disabled {
    opacity: 0.2; }

.swatch-filter-indigoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #233a7d;
  border-radius: 0.69em;
  background-color: #233a7d;
  display: block;
  position: relative; }
  .swatch-filter-indigoblue.disabled {
    opacity: 0.2; }

.swatch-circle-infinitypool,
.swatch-circle-infinitypool.large-swatch:after {
  background-color: #00646b; }

.swatch-square-infinitypool {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #00646b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-infinitypool.disabled {
    opacity: 0.2; }

.swatch-filter-infinitypool {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #00646b;
  border-radius: 0.69em;
  background-color: #00646b;
  display: block;
  position: relative; }
  .swatch-filter-infinitypool.disabled {
    opacity: 0.2; }

.swatch-circle-infrared,
.swatch-circle-infrared.large-swatch:after {
  background-color: #a50320; }

.swatch-square-infrared {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a50320;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-infrared.disabled {
    opacity: 0.2; }

.swatch-filter-infrared {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a50320;
  border-radius: 0.69em;
  background-color: #a50320;
  display: block;
  position: relative; }
  .swatch-filter-infrared.disabled {
    opacity: 0.2; }

.swatch-circle-ink,
.swatch-circle-ink.large-swatch:after {
  background-color: #3f3a66; }

.swatch-square-ink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3f3a66;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ink.disabled {
    opacity: 0.2; }

.swatch-filter-ink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3f3a66;
  border-radius: 0.69em;
  background-color: #3f3a66;
  display: block;
  position: relative; }
  .swatch-filter-ink.disabled {
    opacity: 0.2; }

.swatch-circle-ivory,
.swatch-circle-ivory.large-swatch:after {
  background-color: #fff6d5; }

.swatch-square-ivory {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fff6d5;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ivory.disabled {
    opacity: 0.2; }

.swatch-filter-ivory {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff6d5;
  border-radius: 0.69em;
  background-color: #fff6d5;
  display: block;
  position: relative; }
  .swatch-filter-ivory.disabled {
    opacity: 0.2; }

.swatch-circle-jade,
.swatch-circle-jade.large-swatch:after {
  background-color: #00b38a; }

.swatch-square-jade {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #00b38a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-jade.disabled {
    opacity: 0.2; }

.swatch-filter-jade {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #00b38a;
  border-radius: 0.69em;
  background-color: #00b38a;
  display: block;
  position: relative; }
  .swatch-filter-jade.disabled {
    opacity: 0.2; }

.swatch-circle-jet,
.swatch-circle-jet.large-swatch:after {
  background-color: #3f3e44; }

.swatch-square-jet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3f3e44;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-jet.disabled {
    opacity: 0.2; }

.swatch-filter-jet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3f3e44;
  border-radius: 0.69em;
  background-color: #3f3e44;
  display: block;
  position: relative; }
  .swatch-filter-jet.disabled {
    opacity: 0.2; }

.swatch-circle-jetsetred,
.swatch-circle-jetsetred.large-swatch:after {
  background-color: #930324; }

.swatch-square-jetsetred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #930324;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-jetsetred.disabled {
    opacity: 0.2; }

.swatch-filter-jetsetred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #930324;
  border-radius: 0.69em;
  background-color: #930324;
  display: block;
  position: relative; }
  .swatch-filter-jetsetred.disabled {
    opacity: 0.2; }

.swatch-circle-julep,
.swatch-circle-julep.large-swatch:after {
  background-color: #a5d0d1; }

.swatch-square-julep {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a5d0d1;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-julep.disabled {
    opacity: 0.2; }

.swatch-filter-julep {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a5d0d1;
  border-radius: 0.69em;
  background-color: #a5d0d1;
  display: block;
  position: relative; }
  .swatch-filter-julep.disabled {
    opacity: 0.2; }

.swatch-circle-khaki,
.swatch-circle-khaki.large-swatch:after {
  background-color: #4f3b25; }

.swatch-square-khaki {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4f3b25;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-khaki.disabled {
    opacity: 0.2; }

.swatch-filter-khaki {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4f3b25;
  border-radius: 0.69em;
  background-color: #4f3b25;
  display: block;
  position: relative; }
  .swatch-filter-khaki.disabled {
    opacity: 0.2; }

.swatch-circle-lacquer,
.swatch-circle-lacquer.large-swatch:after {
  background-color: #1d428a; }

.swatch-square-lacquer {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1d428a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lacquer.disabled {
    opacity: 0.2; }

.swatch-filter-lacquer {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1d428a;
  border-radius: 0.69em;
  background-color: #1d428a;
  display: block;
  position: relative; }
  .swatch-filter-lacquer.disabled {
    opacity: 0.2; }

.swatch-circle-lagoon,
.swatch-circle-lagoon.large-swatch:after {
  background-color: #0098aa; }

.swatch-square-lagoon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0098aa;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lagoon.disabled {
    opacity: 0.2; }

.swatch-filter-lagoon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0098aa;
  border-radius: 0.69em;
  background-color: #0098aa;
  display: block;
  position: relative; }
  .swatch-filter-lagoon.disabled {
    opacity: 0.2; }

.swatch-circle-lavendar,
.swatch-circle-lavendar.large-swatch:after {
  background-color: #d8b6d7; }

.swatch-square-lavendar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d8b6d7;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lavendar.disabled {
    opacity: 0.2; }

.swatch-filter-lavendar {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d8b6d7;
  border-radius: 0.69em;
  background-color: #d8b6d7;
  display: block;
  position: relative; }
  .swatch-filter-lavendar.disabled {
    opacity: 0.2; }

.swatch-circle-lavender,
.swatch-circle-lavender.large-swatch:after {
  background-color: #b8acd6; }

.swatch-square-lavender {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #b8acd6;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lavender.disabled {
    opacity: 0.2; }

.swatch-filter-lavender {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #b8acd6;
  border-radius: 0.69em;
  background-color: #b8acd6;
  display: block;
  position: relative; }
  .swatch-filter-lavender.disabled {
    opacity: 0.2; }

.swatch-circle-lavenderpurple,
.swatch-circle-lavenderpurple.large-swatch:after {
  background-color: #dcd5f4; }

.swatch-square-lavenderpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #dcd5f4;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lavenderpurple.disabled {
    opacity: 0.2; }

.swatch-filter-lavenderpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #dcd5f4;
  border-radius: 0.69em;
  background-color: #dcd5f4;
  display: block;
  position: relative; }
  .swatch-filter-lavenderpurple.disabled {
    opacity: 0.2; }

.swatch-circle-lightblue,
.swatch-circle-lightblue.large-swatch:after {
  background-color: #5991ca; }

.swatch-square-lightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #5991ca;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lightblue.disabled {
    opacity: 0.2; }

.swatch-filter-lightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #5991ca;
  border-radius: 0.69em;
  background-color: #5991ca;
  display: block;
  position: relative; }
  .swatch-filter-lightblue.disabled {
    opacity: 0.2; }

.swatch-circle-lightgraphite,
.swatch-circle-lightgraphite.large-swatch:after {
  background-color: #8f8f93; }

.swatch-square-lightgraphite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8f8f93;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lightgraphite.disabled {
    opacity: 0.2; }

.swatch-filter-lightgraphite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f8f93;
  border-radius: 0.69em;
  background-color: #8f8f93;
  display: block;
  position: relative; }
  .swatch-filter-lightgraphite.disabled {
    opacity: 0.2; }

.swatch-circle-lightgray,
.swatch-circle-lightgray.large-swatch:after {
  background-color: #bebebe; }

.swatch-square-lightgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bebebe;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lightgray.disabled {
    opacity: 0.2; }

.swatch-filter-lightgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bebebe;
  border-radius: 0.69em;
  background-color: #bebebe;
  display: block;
  position: relative; }
  .swatch-filter-lightgray.disabled {
    opacity: 0.2; }

.swatch-circle-lightlines,
.swatch-circle-lightlines.large-swatch:after {
  background-color: #d6d4cf; }

.swatch-square-lightlines {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d6d4cf;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lightlines.disabled {
    opacity: 0.2; }

.swatch-filter-lightlines {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d6d4cf;
  border-radius: 0.69em;
  background-color: #d6d4cf;
  display: block;
  position: relative; }
  .swatch-filter-lightlines.disabled {
    opacity: 0.2; }

.swatch-circle-lightpink,
.swatch-circle-lightpink.large-swatch:after {
  background-color: #b8575f; }

.swatch-square-lightpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #b8575f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lightpink.disabled {
    opacity: 0.2; }

.swatch-filter-lightpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #b8575f;
  border-radius: 0.69em;
  background-color: #b8575f;
  display: block;
  position: relative; }
  .swatch-filter-lightpink.disabled {
    opacity: 0.2; }

.swatch-circle-lilac,
.swatch-circle-lilac.large-swatch:after {
  background-color: #d2bbda; }

.swatch-square-lilac {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d2bbda;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lilac.disabled {
    opacity: 0.2; }

.swatch-filter-lilac {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d2bbda;
  border-radius: 0.69em;
  background-color: #d2bbda;
  display: block;
  position: relative; }
  .swatch-filter-lilac.disabled {
    opacity: 0.2; }

.swatch-circle-lime,
.swatch-circle-lime.large-swatch:after {
  background-color: #d7e241; }

.swatch-square-lime {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d7e241;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-lime.disabled {
    opacity: 0.2; }

.swatch-filter-lime {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d7e241;
  border-radius: 0.69em;
  background-color: #d7e241;
  display: block;
  position: relative; }
  .swatch-filter-lime.disabled {
    opacity: 0.2; }

.swatch-circle-limelight,
.swatch-circle-limelight.large-swatch:after {
  background-color: #c9dd00; }

.swatch-square-limelight {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c9dd00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-limelight.disabled {
    opacity: 0.2; }

.swatch-filter-limelight {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c9dd00;
  border-radius: 0.69em;
  background-color: #c9dd00;
  display: block;
  position: relative; }
  .swatch-filter-limelight.disabled {
    opacity: 0.2; }

.swatch-circle-magnetic,
.swatch-circle-magnetic.large-swatch:after {
  background-color: #a50069; }

.swatch-square-magnetic {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a50069;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-magnetic.disabled {
    opacity: 0.2; }

.swatch-filter-magnetic {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a50069;
  border-radius: 0.69em;
  background-color: #a50069;
  display: block;
  position: relative; }
  .swatch-filter-magnetic.disabled {
    opacity: 0.2; }

.swatch-circle-majesty,
.swatch-circle-majesty.large-swatch:after {
  background-color: #5d4171; }

.swatch-square-majesty {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #5d4171;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-majesty.disabled {
    opacity: 0.2; }

.swatch-filter-majesty {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #5d4171;
  border-radius: 0.69em;
  background-color: #5d4171;
  display: block;
  position: relative; }
  .swatch-filter-majesty.disabled {
    opacity: 0.2; }

.swatch-circle-mango,
.swatch-circle-mango.large-swatch:after {
  background-color: #f6d654; }

.swatch-square-mango {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f6d654;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mango.disabled {
    opacity: 0.2; }

.swatch-filter-mango {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f6d654;
  border-radius: 0.69em;
  background-color: #f6d654;
  display: block;
  position: relative; }
  .swatch-filter-mango.disabled {
    opacity: 0.2; }

.swatch-circle-marina,
.swatch-circle-marina.large-swatch:after {
  background-color: #0090cd; }

.swatch-square-marina {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0090cd;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-marina.disabled {
    opacity: 0.2; }

.swatch-filter-marina {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0090cd;
  border-radius: 0.69em;
  background-color: #0090cd;
  display: block;
  position: relative; }
  .swatch-filter-marina.disabled {
    opacity: 0.2; }

.swatch-circle-marinablue,
.swatch-circle-marinablue.large-swatch:after {
  background-color: #0090cd; }

.swatch-square-marinablue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0090cd;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-marinablue.disabled {
    opacity: 0.2; }

.swatch-filter-marinablue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0090cd;
  border-radius: 0.69em;
  background-color: #0090cd;
  display: block;
  position: relative; }
  .swatch-filter-marinablue.disabled {
    opacity: 0.2; }

.swatch-circle-mask,
.swatch-circle-mask.large-swatch:after {
  background-color: #22314e; }

.swatch-square-mask {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #22314e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mask.disabled {
    opacity: 0.2; }

.swatch-filter-mask {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #22314e;
  border-radius: 0.69em;
  background-color: #22314e;
  display: block;
  position: relative; }
  .swatch-filter-mask.disabled {
    opacity: 0.2; }

.swatch-circle-mauve,
.swatch-circle-mauve.large-swatch:after {
  background-color: #c873af; }

.swatch-square-mauve {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c873af;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mauve.disabled {
    opacity: 0.2; }

.swatch-filter-mauve {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c873af;
  border-radius: 0.69em;
  background-color: #c873af;
  display: block;
  position: relative; }
  .swatch-filter-mauve.disabled {
    opacity: 0.2; }

.swatch-circle-mellowpink,
.swatch-circle-mellowpink.large-swatch:after {
  background-color: #ecc7d2; }

.swatch-square-mellowpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ecc7d2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mellowpink.disabled {
    opacity: 0.2; }

.swatch-filter-mellowpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ecc7d2;
  border-radius: 0.69em;
  background-color: #ecc7d2;
  display: block;
  position: relative; }
  .swatch-filter-mellowpink.disabled {
    opacity: 0.2; }

.swatch-circle-midnight,
.swatch-circle-midnight.large-swatch:after {
  background-color: #35363b; }

.swatch-square-midnight {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #35363b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-midnight.disabled {
    opacity: 0.2; }

.swatch-filter-midnight {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #35363b;
  border-radius: 0.69em;
  background-color: #35363b;
  display: block;
  position: relative; }
  .swatch-filter-midnight.disabled {
    opacity: 0.2; }

.swatch-circle-midnightblack,
.swatch-circle-midnightblack.large-swatch:after {
  background-color: #454545; }

.swatch-square-midnightblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #454545;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-midnightblack.disabled {
    opacity: 0.2; }

.swatch-filter-midnightblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #454545;
  border-radius: 0.69em;
  background-color: #454545;
  display: block;
  position: relative; }
  .swatch-filter-midnightblack.disabled {
    opacity: 0.2; }

.swatch-circle-midnightblue,
.swatch-circle-midnightblue.large-swatch:after {
  background-color: #1a2b51; }

.swatch-square-midnightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1a2b51;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-midnightblue.disabled {
    opacity: 0.2; }

.swatch-filter-midnightblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1a2b51;
  border-radius: 0.69em;
  background-color: #1a2b51;
  display: block;
  position: relative; }
  .swatch-filter-midnightblue.disabled {
    opacity: 0.2; }

.swatch-circle-midnightgreen,
.swatch-circle-midnightgreen.large-swatch:after {
  background-color: #485b53; }

.swatch-square-midnightgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #485b53;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-midnightgreen.disabled {
    opacity: 0.2; }

.swatch-filter-midnightgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #485b53;
  border-radius: 0.69em;
  background-color: #485b53;
  display: block;
  position: relative; }
  .swatch-filter-midnightgreen.disabled {
    opacity: 0.2; }

.swatch-circle-midorange,
.swatch-circle-midorange.large-swatch:after {
  background-color: #c8532c; }

.swatch-square-midorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c8532c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-midorange.disabled {
    opacity: 0.2; }

.swatch-filter-midorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c8532c;
  border-radius: 0.69em;
  background-color: #c8532c;
  display: block;
  position: relative; }
  .swatch-filter-midorange.disabled {
    opacity: 0.2; }

.swatch-circle-millennialpink,
.swatch-circle-millennialpink.large-swatch:after {
  background-color: #e6cecb; }

.swatch-square-millennialpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e6cecb;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-millennialpink.disabled {
    opacity: 0.2; }

.swatch-filter-millennialpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e6cecb;
  border-radius: 0.69em;
  background-color: #e6cecb;
  display: block;
  position: relative; }
  .swatch-filter-millennialpink.disabled {
    opacity: 0.2; }

.swatch-circle-mint,
.swatch-circle-mint.large-swatch:after {
  background-color: #73d1b7; }

.swatch-square-mint {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #73d1b7;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mint.disabled {
    opacity: 0.2; }

.swatch-filter-mint {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #73d1b7;
  border-radius: 0.69em;
  background-color: #73d1b7;
  display: block;
  position: relative; }
  .swatch-filter-mint.disabled {
    opacity: 0.2; }

.swatch-circle-mixitblue,
.swatch-circle-mixitblue.large-swatch:after {
  background-color: #1e93be; }

.swatch-square-mixitblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1e93be;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mixitblue.disabled {
    opacity: 0.2; }

.swatch-filter-mixitblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1e93be;
  border-radius: 0.69em;
  background-color: #1e93be;
  display: block;
  position: relative; }
  .swatch-filter-mixitblue.disabled {
    opacity: 0.2; }

.swatch-circle-mixitred,
.swatch-circle-mixitred.large-swatch:after {
  background-color: #bc302d; }

.swatch-square-mixitred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bc302d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mixitred.disabled {
    opacity: 0.2; }

.swatch-filter-mixitred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bc302d;
  border-radius: 0.69em;
  background-color: #bc302d;
  display: block;
  position: relative; }
  .swatch-filter-mixitred.disabled {
    opacity: 0.2; }

.swatch-circle-monument,
.swatch-circle-monument.large-swatch:after {
  background-color: #484443; }

.swatch-square-monument {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #484443;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-monument.disabled {
    opacity: 0.2; }

.swatch-filter-monument {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #484443;
  border-radius: 0.69em;
  background-color: #484443;
  display: block;
  position: relative; }
  .swatch-filter-monument.disabled {
    opacity: 0.2; }

.swatch-circle-mustard,
.swatch-circle-mustard.large-swatch:after {
  background-color: #c9ba4e; }

.swatch-square-mustard {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c9ba4e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-mustard.disabled {
    opacity: 0.2; }

.swatch-filter-mustard {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c9ba4e;
  border-radius: 0.69em;
  background-color: #c9ba4e;
  display: block;
  position: relative; }
  .swatch-filter-mustard.disabled {
    opacity: 0.2; }

.swatch-circle-navy,
.swatch-circle-navy.large-swatch:after {
  background-color: #2f3e5d; }

.swatch-square-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2f3e5d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2f3e5d;
  border-radius: 0.69em;
  background-color: #2f3e5d;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-navyblue,
.swatch-circle-navyblue.large-swatch:after {
  background-color: #2f3e5d; }

.swatch-square-navyblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2f3e5d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-navyblue.disabled {
    opacity: 0.2; }

.swatch-filter-navyblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2f3e5d;
  border-radius: 0.69em;
  background-color: #2f3e5d;
  display: block;
  position: relative; }
  .swatch-filter-navyblue.disabled {
    opacity: 0.2; }

.swatch-circle-nightfall,
.swatch-circle-nightfall.large-swatch:after {
  background-color: #53396a; }

.swatch-square-nightfall {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #53396a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-nightfall.disabled {
    opacity: 0.2; }

.swatch-filter-nightfall {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #53396a;
  border-radius: 0.69em;
  background-color: #53396a;
  display: block;
  position: relative; }
  .swatch-filter-nightfall.disabled {
    opacity: 0.2; }

.swatch-circle-nightsky,
.swatch-circle-nightsky.large-swatch:after {
  background-color: #1d164c; }

.swatch-square-nightsky {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #1d164c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-nightsky.disabled {
    opacity: 0.2; }

.swatch-filter-nightsky {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #1d164c;
  border-radius: 0.69em;
  background-color: #1d164c;
  display: block;
  position: relative; }
  .swatch-filter-nightsky.disabled {
    opacity: 0.2; }

.swatch-circle-ocean,
.swatch-circle-ocean.large-swatch:after {
  background-color: #235484; }

.swatch-square-ocean {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #235484;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ocean.disabled {
    opacity: 0.2; }

.swatch-filter-ocean {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #235484;
  border-radius: 0.69em;
  background-color: #235484;
  display: block;
  position: relative; }
  .swatch-filter-ocean.disabled {
    opacity: 0.2; }

.swatch-circle-olivegreen,
.swatch-circle-olivegreen.large-swatch:after {
  background-color: #55782d; }

.swatch-square-olivegreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #55782d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-olivegreen.disabled {
    opacity: 0.2; }

.swatch-filter-olivegreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #55782d;
  border-radius: 0.69em;
  background-color: #55782d;
  display: block;
  position: relative; }
  .swatch-filter-olivegreen.disabled {
    opacity: 0.2; }

.swatch-circle-orange,
.swatch-circle-orange.large-swatch:after {
  background-color: #ba4838; }

.swatch-square-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ba4838;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ba4838;
  border-radius: 0.69em;
  background-color: #ba4838;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-orange2,
.swatch-circle-orange2.large-swatch:after {
  background-color: #ba4838; }

.swatch-square-orange2 {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ba4838;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orange2.disabled {
    opacity: 0.2; }

.swatch-filter-orange2 {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ba4838;
  border-radius: 0.69em;
  background-color: #ba4838;
  display: block;
  position: relative; }
  .swatch-filter-orange2.disabled {
    opacity: 0.2; }

.swatch-circle-orchid,
.swatch-circle-orchid.large-swatch:after {
  background-color: #9961c3; }

.swatch-square-orchid {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #9961c3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orchid.disabled {
    opacity: 0.2; }

.swatch-filter-orchid {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #9961c3;
  border-radius: 0.69em;
  background-color: #9961c3;
  display: block;
  position: relative; }
  .swatch-filter-orchid.disabled {
    opacity: 0.2; }

.swatch-circle-overcast,
.swatch-circle-overcast.large-swatch:after {
  background-color: #bdbebf; }

.swatch-square-overcast {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bdbebf;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-overcast.disabled {
    opacity: 0.2; }

.swatch-filter-overcast {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bdbebf;
  border-radius: 0.69em;
  background-color: #bdbebf;
  display: block;
  position: relative; }
  .swatch-filter-overcast.disabled {
    opacity: 0.2; }

.swatch-circle-oystergray,
.swatch-circle-oystergray.large-swatch:after {
  background-color: #666666; }

.swatch-square-oystergray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #666666;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-oystergray.disabled {
    opacity: 0.2; }

.swatch-filter-oystergray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #666666;
  border-radius: 0.69em;
  background-color: #666666;
  display: block;
  position: relative; }
  .swatch-filter-oystergray.disabled {
    opacity: 0.2; }

.swatch-circle-paleblue,
.swatch-circle-paleblue.large-swatch:after {
  background-color: #6f90b8; }

.swatch-square-paleblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6f90b8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-paleblue.disabled {
    opacity: 0.2; }

.swatch-filter-paleblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6f90b8;
  border-radius: 0.69em;
  background-color: #6f90b8;
  display: block;
  position: relative; }
  .swatch-filter-paleblue.disabled {
    opacity: 0.2; }

.swatch-circle-paparazzipink,
.swatch-circle-paparazzipink.large-swatch:after {
  background-color: #d3305d; }

.swatch-square-paparazzipink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d3305d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-paparazzipink.disabled {
    opacity: 0.2; }

.swatch-filter-paparazzipink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d3305d;
  border-radius: 0.69em;
  background-color: #d3305d;
  display: block;
  position: relative; }
  .swatch-filter-paparazzipink.disabled {
    opacity: 0.2; }

.swatch-circle-peacock,
.swatch-circle-peacock.large-swatch:after {
  background-color: #274b4c; }

.swatch-square-peacock {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #274b4c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-peacock.disabled {
    opacity: 0.2; }

.swatch-filter-peacock {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #274b4c;
  border-radius: 0.69em;
  background-color: #274b4c;
  display: block;
  position: relative; }
  .swatch-filter-peacock.disabled {
    opacity: 0.2; }

.swatch-circle-pearlblue,
.swatch-circle-pearlblue.large-swatch:after {
  background-color: #e0ebf2; }

.swatch-square-pearlblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e0ebf2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pearlblue.disabled {
    opacity: 0.2; }

.swatch-filter-pearlblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e0ebf2;
  border-radius: 0.69em;
  background-color: #e0ebf2;
  display: block;
  position: relative; }
  .swatch-filter-pearlblue.disabled {
    opacity: 0.2; }

.swatch-circle-peat,
.swatch-circle-peat.large-swatch:after {
  background-color: #f56936; }

.swatch-square-peat {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f56936;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-peat.disabled {
    opacity: 0.2; }

.swatch-filter-peat {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f56936;
  border-radius: 0.69em;
  background-color: #f56936;
  display: block;
  position: relative; }
  .swatch-filter-peat.disabled {
    opacity: 0.2; }

.swatch-circle-pebble,
.swatch-circle-pebble.large-swatch:after {
  background-color: #b4aeb1; }

.swatch-square-pebble {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #b4aeb1;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pebble.disabled {
    opacity: 0.2; }

.swatch-filter-pebble {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #b4aeb1;
  border-radius: 0.69em;
  background-color: #b4aeb1;
  display: block;
  position: relative; }
  .swatch-filter-pebble.disabled {
    opacity: 0.2; }

.swatch-circle-peony,
.swatch-circle-peony.large-swatch:after {
  background-color: #fbaac7; }

.swatch-square-peony {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fbaac7;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-peony.disabled {
    opacity: 0.2; }

.swatch-filter-peony {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fbaac7;
  border-radius: 0.69em;
  background-color: #fbaac7;
  display: block;
  position: relative; }
  .swatch-filter-peony.disabled {
    opacity: 0.2; }

.swatch-circle-perfectplum,
.swatch-circle-perfectplum.large-swatch:after {
  background-color: #4a3650; }

.swatch-square-perfectplum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4a3650;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-perfectplum.disabled {
    opacity: 0.2; }

.swatch-filter-perfectplum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4a3650;
  border-radius: 0.69em;
  background-color: #4a3650;
  display: block;
  position: relative; }
  .swatch-filter-perfectplum.disabled {
    opacity: 0.2; }

.swatch-circle-persimmon,
.swatch-circle-persimmon.large-swatch:after {
  background-color: #db6618; }

.swatch-square-persimmon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #db6618;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-persimmon.disabled {
    opacity: 0.2; }

.swatch-filter-persimmon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #db6618;
  border-radius: 0.69em;
  background-color: #db6618;
  display: block;
  position: relative; }
  .swatch-filter-persimmon.disabled {
    opacity: 0.2; }

.swatch-circle-petal,
.swatch-circle-petal.large-swatch:after {
  background-color: #f7dce2; }

.swatch-square-petal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f7dce2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-petal.disabled {
    opacity: 0.2; }

.swatch-filter-petal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f7dce2;
  border-radius: 0.69em;
  background-color: #f7dce2;
  display: block;
  position: relative; }
  .swatch-filter-petal.disabled {
    opacity: 0.2; }

.swatch-circle-petalpink,
.swatch-circle-petalpink.large-swatch:after {
  background-color: #fda4bc; }

.swatch-square-petalpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fda4bc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-petalpink.disabled {
    opacity: 0.2; }

.swatch-filter-petalpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fda4bc;
  border-radius: 0.69em;
  background-color: #fda4bc;
  display: block;
  position: relative; }
  .swatch-filter-petalpink.disabled {
    opacity: 0.2; }

.swatch-circle-phantom,
.swatch-circle-phantom.large-swatch:after {
  background-color: #42474c; }

.swatch-square-phantom {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #42474c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-phantom.disabled {
    opacity: 0.2; }

.swatch-filter-phantom {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #42474c;
  border-radius: 0.69em;
  background-color: #42474c;
  display: block;
  position: relative; }
  .swatch-filter-phantom.disabled {
    opacity: 0.2; }

.swatch-circle-pianoblack,
.swatch-circle-pianoblack.large-swatch:after {
  background-color: #000; }

.swatch-square-pianoblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #000;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pianoblack.disabled {
    opacity: 0.2; }

.swatch-filter-pianoblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-pianoblack.disabled {
    opacity: 0.2; }

.swatch-circle-pink,
.swatch-circle-pink.large-swatch:after {
  background-color: #cb6199; }

.swatch-square-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #cb6199;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #cb6199;
  border-radius: 0.69em;
  background-color: #cb6199;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-pinkviolet,
.swatch-circle-pinkviolet.large-swatch:after {
  background-color: #902865; }

.swatch-square-pinkviolet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #902865;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pinkviolet.disabled {
    opacity: 0.2; }

.swatch-filter-pinkviolet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #902865;
  border-radius: 0.69em;
  background-color: #902865;
  display: block;
  position: relative; }
  .swatch-filter-pinkviolet.disabled {
    opacity: 0.2; }

.swatch-circle-pinot,
.swatch-circle-pinot.large-swatch:after {
  background-color: #693462; }

.swatch-square-pinot {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #693462;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pinot.disabled {
    opacity: 0.2; }

.swatch-filter-pinot {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #693462;
  border-radius: 0.69em;
  background-color: #693462;
  display: block;
  position: relative; }
  .swatch-filter-pinot.disabled {
    opacity: 0.2; }

.swatch-circle-pitchblack,
.swatch-circle-pitchblack.large-swatch:after {
  background-color: #020202; }

.swatch-square-pitchblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #020202;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pitchblack.disabled {
    opacity: 0.2; }

.swatch-filter-pitchblack {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #020202;
  border-radius: 0.69em;
  background-color: #020202;
  display: block;
  position: relative; }
  .swatch-filter-pitchblack.disabled {
    opacity: 0.2; }

.swatch-circle-plum,
.swatch-circle-plum.large-swatch:after {
  background-color: #4a3650; }

.swatch-square-plum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4a3650;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-plum.disabled {
    opacity: 0.2; }

.swatch-filter-plum {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4a3650;
  border-radius: 0.69em;
  background-color: #4a3650;
  display: block;
  position: relative; }
  .swatch-filter-plum.disabled {
    opacity: 0.2; }

.swatch-circle-plumberry,
.swatch-circle-plumberry.large-swatch:after {
  background-color: #4d195b; }

.swatch-square-plumberry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4d195b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-plumberry.disabled {
    opacity: 0.2; }

.swatch-filter-plumberry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4d195b;
  border-radius: 0.69em;
  background-color: #4d195b;
  display: block;
  position: relative; }
  .swatch-filter-plumberry.disabled {
    opacity: 0.2; }

.swatch-circle-portred,
.swatch-circle-portred.large-swatch:after {
  background-color: #77212e; }

.swatch-square-portred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #77212e;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-portred.disabled {
    opacity: 0.2; }

.swatch-filter-portred {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #77212e;
  border-radius: 0.69em;
  background-color: #77212e;
  display: block;
  position: relative; }
  .swatch-filter-portred.disabled {
    opacity: 0.2; }

.swatch-circle-punch,
.swatch-circle-punch.large-swatch:after {
  background-color: #c52062; }

.swatch-square-punch {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c52062;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-punch.disabled {
    opacity: 0.2; }

.swatch-filter-punch {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c52062;
  border-radius: 0.69em;
  background-color: #c52062;
  display: block;
  position: relative; }
  .swatch-filter-punch.disabled {
    opacity: 0.2; }

.swatch-circle-purple,
.swatch-circle-purple.large-swatch:after {
  background-color: #6d3c7d; }

.swatch-square-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6d3c7d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6d3c7d;
  border-radius: 0.69em;
  background-color: #6d3c7d;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-purplelightning,
.swatch-circle-purplelightning.large-swatch:after {
  background-color: #6f4181; }

.swatch-square-purplelightning {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6f4181;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-purplelightning.disabled {
    opacity: 0.2; }

.swatch-filter-purplelightning {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6f4181;
  border-radius: 0.69em;
  background-color: #6f4181;
  display: block;
  position: relative; }
  .swatch-filter-purplelightning.disabled {
    opacity: 0.2; }

.swatch-circle-radiantorchid,
.swatch-circle-radiantorchid.large-swatch:after {
  background-color: #a45b96; }

.swatch-square-radiantorchid {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a45b96;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-radiantorchid.disabled {
    opacity: 0.2; }

.swatch-filter-radiantorchid {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a45b96;
  border-radius: 0.69em;
  background-color: #a45b96;
  display: block;
  position: relative; }
  .swatch-filter-radiantorchid.disabled {
    opacity: 0.2; }

.swatch-circle-red,
.swatch-circle-red.large-swatch:after {
  background-color: #d3222a; }

.swatch-square-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d3222a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d3222a;
  border-radius: 0.69em;
  background-color: #d3222a;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-redcarpet,
.swatch-circle-redcarpet.large-swatch:after {
  background-color: #9d202f; }

.swatch-square-redcarpet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #9d202f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-redcarpet.disabled {
    opacity: 0.2; }

.swatch-filter-redcarpet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #9d202f;
  border-radius: 0.69em;
  background-color: #9d202f;
  display: block;
  position: relative; }
  .swatch-filter-redcarpet.disabled {
    opacity: 0.2; }

.swatch-circle-redviolet,
.swatch-circle-redviolet.large-swatch:after {
  background-color: #6c3a77; }

.swatch-square-redviolet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #6c3a77;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-redviolet.disabled {
    opacity: 0.2; }

.swatch-filter-redviolet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #6c3a77;
  border-radius: 0.69em;
  background-color: #6c3a77;
  display: block;
  position: relative; }
  .swatch-filter-redviolet.disabled {
    opacity: 0.2; }

.swatch-circle-reflection,
.swatch-circle-reflection.large-swatch:after {
  background-color: #00aed9; }

.swatch-square-reflection {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #00aed9;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-reflection.disabled {
    opacity: 0.2; }

.swatch-filter-reflection {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #00aed9;
  border-radius: 0.69em;
  background-color: #00aed9;
  display: block;
  position: relative; }
  .swatch-filter-reflection.disabled {
    opacity: 0.2; }

.swatch-circle-rooftopgreen,
.swatch-circle-rooftopgreen.large-swatch:after {
  background-color: #68c448; }

.swatch-square-rooftopgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #68c448;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-rooftopgreen.disabled {
    opacity: 0.2; }

.swatch-filter-rooftopgreen {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #68c448;
  border-radius: 0.69em;
  background-color: #68c448;
  display: block;
  position: relative; }
  .swatch-filter-rooftopgreen.disabled {
    opacity: 0.2; }

.swatch-circle-rose,
.swatch-circle-rose.large-swatch:after {
  background-color: #8b2346; }

.swatch-square-rose {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8b2346;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-rose.disabled {
    opacity: 0.2; }

.swatch-filter-rose {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8b2346;
  border-radius: 0.69em;
  background-color: #8b2346;
  display: block;
  position: relative; }
  .swatch-filter-rose.disabled {
    opacity: 0.2; }

.swatch-circle-rosegold,
.swatch-circle-rosegold.large-swatch:after {
  background-color: #edd7cb; }

.swatch-square-rosegold {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #edd7cb;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-rosegold.disabled {
    opacity: 0.2; }

.swatch-filter-rosegold {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #edd7cb;
  border-radius: 0.69em;
  background-color: #edd7cb;
  display: block;
  position: relative; }
  .swatch-filter-rosegold.disabled {
    opacity: 0.2; }

.swatch-circle-rosequartz,
.swatch-circle-rosequartz.large-swatch:after {
  background-color: #f1d6c9; }

.swatch-square-rosequartz {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f1d6c9;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-rosequartz.disabled {
    opacity: 0.2; }

.swatch-filter-rosequartz {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f1d6c9;
  border-radius: 0.69em;
  background-color: #f1d6c9;
  display: block;
  position: relative; }
  .swatch-filter-rosequartz.disabled {
    opacity: 0.2; }

.swatch-circle-royallilac,
.swatch-circle-royallilac.large-swatch:after {
  background-color: #8c0628; }

.swatch-square-royallilac {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8c0628;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-royallilac.disabled {
    opacity: 0.2; }

.swatch-filter-royallilac {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8c0628;
  border-radius: 0.69em;
  background-color: #8c0628;
  display: block;
  position: relative; }
  .swatch-filter-royallilac.disabled {
    opacity: 0.2; }

.swatch-circle-royalpurple,
.swatch-circle-royalpurple.large-swatch:after {
  background-color: #591c65; }

.swatch-square-royalpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #591c65;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-royalpurple.disabled {
    opacity: 0.2; }

.swatch-filter-royalpurple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #591c65;
  border-radius: 0.69em;
  background-color: #591c65;
  display: block;
  position: relative; }
  .swatch-filter-royalpurple.disabled {
    opacity: 0.2; }

.swatch-circle-ruby,
.swatch-circle-ruby.large-swatch:after {
  background-color: #d12631; }

.swatch-square-ruby {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d12631;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-ruby.disabled {
    opacity: 0.2; }

.swatch-filter-ruby {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d12631;
  border-radius: 0.69em;
  background-color: #d12631;
  display: block;
  position: relative; }
  .swatch-filter-ruby.disabled {
    opacity: 0.2; }

.swatch-circle-safetyorange,
.swatch-circle-safetyorange.large-swatch:after {
  background-color: #db4834; }

.swatch-square-safetyorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #db4834;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-safetyorange.disabled {
    opacity: 0.2; }

.swatch-filter-safetyorange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #db4834;
  border-radius: 0.69em;
  background-color: #db4834;
  display: block;
  position: relative; }
  .swatch-filter-safetyorange.disabled {
    opacity: 0.2; }

.swatch-circle-sand,
.swatch-circle-sand.large-swatch:after {
  background-color: #f2eee8; }

.swatch-square-sand {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f2eee8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sand.disabled {
    opacity: 0.2; }

.swatch-filter-sand {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f2eee8;
  border-radius: 0.69em;
  background-color: #f2eee8;
  display: block;
  position: relative; }
  .swatch-filter-sand.disabled {
    opacity: 0.2; }

.swatch-circle-shadow,
.swatch-circle-shadow.large-swatch:after {
  background-color: #2c3f5f; }

.swatch-square-shadow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2c3f5f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-shadow.disabled {
    opacity: 0.2; }

.swatch-filter-shadow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2c3f5f;
  border-radius: 0.69em;
  background-color: #2c3f5f;
  display: block;
  position: relative; }
  .swatch-filter-shadow.disabled {
    opacity: 0.2; }

.swatch-circle-shadowmetallic,
.swatch-circle-shadowmetallic.large-swatch:after {
  background-color: #4d4d4b; }

.swatch-square-shadowmetallic {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4d4d4b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-shadowmetallic.disabled {
    opacity: 0.2; }

.swatch-filter-shadowmetallic {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4d4d4b;
  border-radius: 0.69em;
  background-color: #4d4d4b;
  display: block;
  position: relative; }
  .swatch-filter-shadowmetallic.disabled {
    opacity: 0.2; }

.swatch-circle-shellpink,
.swatch-circle-shellpink.large-swatch:after {
  background-color: #f6e1df; }

.swatch-square-shellpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f6e1df;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-shellpink.disabled {
    opacity: 0.2; }

.swatch-filter-shellpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f6e1df;
  border-radius: 0.69em;
  background-color: #f6e1df;
  display: block;
  position: relative; }
  .swatch-filter-shellpink.disabled {
    opacity: 0.2; }

.swatch-circle-sidewalk,
.swatch-circle-sidewalk.large-swatch:after {
  background-color: #8a8f90; }

.swatch-square-sidewalk {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8a8f90;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sidewalk.disabled {
    opacity: 0.2; }

.swatch-filter-sidewalk {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8a8f90;
  border-radius: 0.69em;
  background-color: #8a8f90;
  display: block;
  position: relative; }
  .swatch-filter-sidewalk.disabled {
    opacity: 0.2; }

.swatch-circle-silver,
.swatch-circle-silver.large-swatch:after {
  background-color: #8e908f; }

.swatch-square-silver {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #8e908f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-silver.disabled {
    opacity: 0.2; }

.swatch-filter-silver {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8e908f;
  border-radius: 0.69em;
  background-color: #8e908f;
  display: block;
  position: relative; }
  .swatch-filter-silver.disabled {
    opacity: 0.2; }

.swatch-circle-slate,
.swatch-circle-slate.large-swatch:after {
  background-color: #606b78; }

.swatch-square-slate {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #606b78;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-slate.disabled {
    opacity: 0.2; }

.swatch-filter-slate {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #606b78;
  border-radius: 0.69em;
  background-color: #606b78;
  display: block;
  position: relative; }
  .swatch-filter-slate.disabled {
    opacity: 0.2; }

.swatch-circle-smoke,
.swatch-circle-smoke.large-swatch:after {
  background-color: #616161; }

.swatch-square-smoke {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #616161;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-smoke.disabled {
    opacity: 0.2; }

.swatch-filter-smoke {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #616161;
  border-radius: 0.69em;
  background-color: #616161;
  display: block;
  position: relative; }
  .swatch-filter-smoke.disabled {
    opacity: 0.2; }

.swatch-circle-smolder,
.swatch-circle-smolder.large-swatch:after {
  background-color: #41403f; }

.swatch-square-smolder {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #41403f;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-smolder.disabled {
    opacity: 0.2; }

.swatch-filter-smolder {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #41403f;
  border-radius: 0.69em;
  background-color: #41403f;
  display: block;
  position: relative; }
  .swatch-filter-smolder.disabled {
    opacity: 0.2; }

.swatch-circle-snowcapwhite,
.swatch-circle-snowcapwhite.large-swatch:after {
  background-color: #f9fafa; }

.swatch-square-snowcapwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f9fafa;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-snowcapwhite.disabled {
    opacity: 0.2; }

.swatch-filter-snowcapwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f9fafa;
  border-radius: 0.69em;
  background-color: #f9fafa;
  display: block;
  position: relative; }
  .swatch-filter-snowcapwhite.disabled {
    opacity: 0.2; }

.swatch-circle-softgray,
.swatch-circle-softgray.large-swatch:after {
  background-color: #3c393a; }

.swatch-square-softgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #3c393a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-softgray.disabled {
    opacity: 0.2; }

.swatch-filter-softgray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #3c393a;
  border-radius: 0.69em;
  background-color: #3c393a;
  display: block;
  position: relative; }
  .swatch-filter-softgray.disabled {
    opacity: 0.2; }

.swatch-circle-sorbet,
.swatch-circle-sorbet.large-swatch:after {
  background-color: #e14c68; }

.swatch-square-sorbet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #e14c68;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sorbet.disabled {
    opacity: 0.2; }

.swatch-filter-sorbet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #e14c68;
  border-radius: 0.69em;
  background-color: #e14c68;
  display: block;
  position: relative; }
  .swatch-filter-sorbet.disabled {
    opacity: 0.2; }

.swatch-circle-spacegray,
.swatch-circle-spacegray.large-swatch:after {
  background-color: #626568; }

.swatch-square-spacegray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #626568;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-spacegray.disabled {
    opacity: 0.2; }

.swatch-filter-spacegray {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #626568;
  border-radius: 0.69em;
  background-color: #626568;
  display: block;
  position: relative; }
  .swatch-filter-spacegray.disabled {
    opacity: 0.2; }

.swatch-circle-sparkle,
.swatch-circle-sparkle.large-swatch:after {
  background-color: #bab7b4; }

.swatch-square-sparkle {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #bab7b4;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sparkle.disabled {
    opacity: 0.2; }

.swatch-filter-sparkle {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #bab7b4;
  border-radius: 0.69em;
  background-color: #bab7b4;
  display: block;
  position: relative; }
  .swatch-filter-sparkle.disabled {
    opacity: 0.2; }

.swatch-circle-sparklinggrape,
.swatch-circle-sparklinggrape.large-swatch:after {
  background-color: #642f6c; }

.swatch-square-sparklinggrape {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #642f6c;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sparklinggrape.disabled {
    opacity: 0.2; }

.swatch-filter-sparklinggrape {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #642f6c;
  border-radius: 0.69em;
  background-color: #642f6c;
  display: block;
  position: relative; }
  .swatch-filter-sparklinggrape.disabled {
    opacity: 0.2; }

.swatch-circle-sportyellow,
.swatch-circle-sportyellow.large-swatch:after {
  background-color: #f1eb54; }

.swatch-square-sportyellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f1eb54;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sportyellow.disabled {
    opacity: 0.2; }

.swatch-filter-sportyellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f1eb54;
  border-radius: 0.69em;
  background-color: #f1eb54;
  display: block;
  position: relative; }
  .swatch-filter-sportyellow.disabled {
    opacity: 0.2; }

.swatch-circle-steel,
.swatch-circle-steel.large-swatch:after {
  background-color: #84888b; }

.swatch-square-steel {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #84888b;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-steel.disabled {
    opacity: 0.2; }

.swatch-filter-steel {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #84888b;
  border-radius: 0.69em;
  background-color: #84888b;
  display: block;
  position: relative; }
  .swatch-filter-steel.disabled {
    opacity: 0.2; }

.swatch-circle-stockbrown,
.swatch-circle-stockbrown.large-swatch:after {
  background-color: #60473a; }

.swatch-square-stockbrown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #60473a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-stockbrown.disabled {
    opacity: 0.2; }

.swatch-filter-stockbrown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #60473a;
  border-radius: 0.69em;
  background-color: #60473a;
  display: block;
  position: relative; }
  .swatch-filter-stockbrown.disabled {
    opacity: 0.2; }

.swatch-circle-stone,
.swatch-circle-stone.large-swatch:after {
  background-color: #a2a5a4; }

.swatch-square-stone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a2a5a4;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-stone.disabled {
    opacity: 0.2; }

.swatch-filter-stone {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a2a5a4;
  border-radius: 0.69em;
  background-color: #a2a5a4;
  display: block;
  position: relative; }
  .swatch-filter-stone.disabled {
    opacity: 0.2; }

.swatch-circle-stripe,
.swatch-circle-stripe.large-swatch:after {
  background-color: #b5b5b5; }

.swatch-square-stripe {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #b5b5b5;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-stripe.disabled {
    opacity: 0.2; }

.swatch-filter-stripe {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #b5b5b5;
  border-radius: 0.69em;
  background-color: #b5b5b5;
  display: block;
  position: relative; }
  .swatch-filter-stripe.disabled {
    opacity: 0.2; }

.swatch-circle-sunsetpink,
.swatch-circle-sunsetpink.large-swatch:after {
  background-color: #f25f66; }

.swatch-square-sunsetpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f25f66;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-sunsetpink.disabled {
    opacity: 0.2; }

.swatch-filter-sunsetpink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f25f66;
  border-radius: 0.69em;
  background-color: #f25f66;
  display: block;
  position: relative; }
  .swatch-filter-sunsetpink.disabled {
    opacity: 0.2; }

.swatch-circle-surge,
.swatch-circle-surge.large-swatch:after {
  background-color: #ff7d00; }

.swatch-square-surge {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ff7d00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-surge.disabled {
    opacity: 0.2; }

.swatch-filter-surge {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff7d00;
  border-radius: 0.69em;
  background-color: #ff7d00;
  display: block;
  position: relative; }
  .swatch-filter-surge.disabled {
    opacity: 0.2; }

.swatch-circle-swim,
.swatch-circle-swim.large-swatch:after {
  background-color: #a2dae8; }

.swatch-square-swim {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a2dae8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-swim.disabled {
    opacity: 0.2; }

.swatch-filter-swim {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a2dae8;
  border-radius: 0.69em;
  background-color: #a2dae8;
  display: block;
  position: relative; }
  .swatch-filter-swim.disabled {
    opacity: 0.2; }

.swatch-circle-tan,
.swatch-circle-tan.large-swatch:after {
  background-color: #d2ccb8; }

.swatch-square-tan {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #d2ccb8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-tan.disabled {
    opacity: 0.2; }

.swatch-filter-tan {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d2ccb8;
  border-radius: 0.69em;
  background-color: #d2ccb8;
  display: block;
  position: relative; }
  .swatch-filter-tan.disabled {
    opacity: 0.2; }

.swatch-circle-taro,
.swatch-circle-taro.large-swatch:after {
  background-color: #9f7ca6; }

.swatch-square-taro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #9f7ca6;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-taro.disabled {
    opacity: 0.2; }

.swatch-filter-taro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #9f7ca6;
  border-radius: 0.69em;
  background-color: #9f7ca6;
  display: block;
  position: relative; }
  .swatch-filter-taro.disabled {
    opacity: 0.2; }

.swatch-circle-tarragon,
.swatch-circle-tarragon.large-swatch:after {
  background-color: #aabc59; }

.swatch-square-tarragon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #aabc59;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-tarragon.disabled {
    opacity: 0.2; }

.swatch-filter-tarragon {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #aabc59;
  border-radius: 0.69em;
  background-color: #aabc59;
  display: block;
  position: relative; }
  .swatch-filter-tarragon.disabled {
    opacity: 0.2; }

.swatch-circle-taupe,
.swatch-circle-taupe.large-swatch:after {
  background-color: #c9c2b0; }

.swatch-square-taupe {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c9c2b0;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-taupe.disabled {
    opacity: 0.2; }

.swatch-filter-taupe {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c9c2b0;
  border-radius: 0.69em;
  background-color: #c9c2b0;
  display: block;
  position: relative; }
  .swatch-filter-taupe.disabled {
    opacity: 0.2; }

.swatch-circle-teal,
.swatch-circle-teal.large-swatch:after {
  background-color: #2e6464; }

.swatch-square-teal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #2e6464;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-teal.disabled {
    opacity: 0.2; }

.swatch-filter-teal {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #2e6464;
  border-radius: 0.69em;
  background-color: #2e6464;
  display: block;
  position: relative; }
  .swatch-filter-teal.disabled {
    opacity: 0.2; }

.swatch-circle-technoblue,
.swatch-circle-technoblue.large-swatch:after {
  background-color: #0291da; }

.swatch-square-technoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0291da;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-technoblue.disabled {
    opacity: 0.2; }

.swatch-filter-technoblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0291da;
  border-radius: 0.69em;
  background-color: #0291da;
  display: block;
  position: relative; }
  .swatch-filter-technoblue.disabled {
    opacity: 0.2; }

.swatch-circle-titanium,
.swatch-circle-titanium.large-swatch:after {
  background-color: #222325; }

.swatch-square-titanium {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #222325;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-titanium.disabled {
    opacity: 0.2; }

.swatch-filter-titanium {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #222325;
  border-radius: 0.69em;
  background-color: #222325;
  display: block;
  position: relative; }
  .swatch-filter-titanium.disabled {
    opacity: 0.2; }

.swatch-circle-toffee,
.swatch-circle-toffee.large-swatch:after {
  background-color: #965224; }

.swatch-square-toffee {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #965224;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-toffee.disabled {
    opacity: 0.2; }

.swatch-filter-toffee {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #965224;
  border-radius: 0.69em;
  background-color: #965224;
  display: block;
  position: relative; }
  .swatch-filter-toffee.disabled {
    opacity: 0.2; }

.swatch-circle-topaz,
.swatch-circle-topaz.large-swatch:after {
  background-color: #0098cd; }

.swatch-square-topaz {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #0098cd;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-topaz.disabled {
    opacity: 0.2; }

.swatch-filter-topaz {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0098cd;
  border-radius: 0.69em;
  background-color: #0098cd;
  display: block;
  position: relative; }
  .swatch-filter-topaz.disabled {
    opacity: 0.2; }

.swatch-circle-tourmaline,
.swatch-circle-tourmaline.large-swatch:after {
  background-color: #afdce2; }

.swatch-square-tourmaline {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #afdce2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-tourmaline.disabled {
    opacity: 0.2; }

.swatch-filter-tourmaline {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #afdce2;
  border-radius: 0.69em;
  background-color: #afdce2;
  display: block;
  position: relative; }
  .swatch-filter-tourmaline.disabled {
    opacity: 0.2; }

.swatch-circle-translucentwhite,
.swatch-circle-translucentwhite.large-swatch:after {
  background-color: #ccc; }

.swatch-square-translucentwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ccc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-translucentwhite.disabled {
    opacity: 0.2; }

.swatch-filter-translucentwhite {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ccc;
  border-radius: 0.69em;
  background-color: #ccc;
  display: block;
  position: relative; }
  .swatch-filter-translucentwhite.disabled {
    opacity: 0.2; }

.swatch-circle-transparent,
.swatch-circle-transparent.large-swatch:after {
  background-color: #fcfcfc; }

.swatch-square-transparent {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fcfcfc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-transparent.disabled {
    opacity: 0.2; }

.swatch-filter-transparent {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fcfcfc;
  border-radius: 0.69em;
  background-color: #fcfcfc;
  display: block;
  position: relative; }
  .swatch-filter-transparent.disabled {
    opacity: 0.2; }

.swatch-circle-uniformblue,
.swatch-circle-uniformblue.large-swatch:after {
  background-color: #233658; }

.swatch-square-uniformblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #233658;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-uniformblue.disabled {
    opacity: 0.2; }

.swatch-filter-uniformblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #233658;
  border-radius: 0.69em;
  background-color: #233658;
  display: block;
  position: relative; }
  .swatch-filter-uniformblue.disabled {
    opacity: 0.2; }

.swatch-circle-verryberry,
.swatch-circle-verryberry.large-swatch:after {
  background-color: #a73e77; }

.swatch-square-verryberry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #a73e77;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-verryberry.disabled {
    opacity: 0.2; }

.swatch-filter-verryberry {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a73e77;
  border-radius: 0.69em;
  background-color: #a73e77;
  display: block;
  position: relative; }
  .swatch-filter-verryberry.disabled {
    opacity: 0.2; }

.swatch-circle-violet,
.swatch-circle-violet.large-swatch:after {
  background-color: #4814a0; }

.swatch-square-violet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #4814a0;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-violet.disabled {
    opacity: 0.2; }

.swatch-filter-violet {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4814a0;
  border-radius: 0.69em;
  background-color: #4814a0;
  display: block;
  position: relative; }
  .swatch-filter-violet.disabled {
    opacity: 0.2; }

.swatch-circle-vividblue,
.swatch-circle-vividblue.large-swatch:after {
  background-color: #005ca8; }

.swatch-square-vividblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #005ca8;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-vividblue.disabled {
    opacity: 0.2; }

.swatch-filter-vividblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #005ca8;
  border-radius: 0.69em;
  background-color: #005ca8;
  display: block;
  position: relative; }
  .swatch-filter-vividblue.disabled {
    opacity: 0.2; }

.swatch-circle-volta,
.swatch-circle-volta.large-swatch:after {
  background-color: #7d5ccb; }

.swatch-square-volta {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #7d5ccb;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-volta.disabled {
    opacity: 0.2; }

.swatch-filter-volta {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #7d5ccb;
  border-radius: 0.69em;
  background-color: #7d5ccb;
  display: block;
  position: relative; }
  .swatch-filter-volta.disabled {
    opacity: 0.2; }

.swatch-circle-walnut,
.swatch-circle-walnut.large-swatch:after {
  background-color: #c2b499; }

.swatch-square-walnut {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c2b499;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-walnut.disabled {
    opacity: 0.2; }

.swatch-filter-walnut {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c2b499;
  border-radius: 0.69em;
  background-color: #c2b499;
  display: block;
  position: relative; }
  .swatch-filter-walnut.disabled {
    opacity: 0.2; }

.swatch-circle-waterfrontblue,
.swatch-circle-waterfrontblue.large-swatch:after {
  background-color: #456083; }

.swatch-square-waterfrontblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #456083;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-waterfrontblue.disabled {
    opacity: 0.2; }

.swatch-filter-waterfrontblue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #456083;
  border-radius: 0.69em;
  background-color: #456083;
  display: block;
  position: relative; }
  .swatch-filter-waterfrontblue.disabled {
    opacity: 0.2; }

.swatch-circle-wauve,
.swatch-circle-wauve.large-swatch:after {
  background-color: #9e85a3; }

.swatch-square-wauve {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #9e85a3;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-wauve.disabled {
    opacity: 0.2; }

.swatch-filter-wauve {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #9e85a3;
  border-radius: 0.69em;
  background-color: #9e85a3;
  display: block;
  position: relative; }
  .swatch-filter-wauve.disabled {
    opacity: 0.2; }

.swatch-circle-white,
.swatch-circle-white.large-swatch:after {
  background-color: #fafafa; }

.swatch-square-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #fafafa;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fafafa;
  border-radius: 0.69em;
  background-color: #fafafa;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-whiteout,
.swatch-circle-whiteout.large-swatch:after {
  background-color: #f1eeed; }

.swatch-square-whiteout {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f1eeed;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-whiteout.disabled {
    opacity: 0.2; }

.swatch-filter-whiteout {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f1eeed;
  border-radius: 0.69em;
  background-color: #f1eeed;
  display: block;
  position: relative; }
  .swatch-filter-whiteout.disabled {
    opacity: 0.2; }

.swatch-circle-whitepearl,
.swatch-circle-whitepearl.large-swatch:after {
  background-color: #c1c1c1; }

.swatch-square-whitepearl {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #c1c1c1;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-whitepearl.disabled {
    opacity: 0.2; }

.swatch-filter-whitepearl {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #c1c1c1;
  border-radius: 0.69em;
  background-color: #c1c1c1;
  display: block;
  position: relative; }
  .swatch-filter-whitepearl.disabled {
    opacity: 0.2; }

.swatch-circle-yellow,
.swatch-circle-yellow.large-swatch:after {
  background-color: #f2fa11; }

.swatch-square-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #f2fa11;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f2fa11;
  border-radius: 0.69em;
  background-color: #f2fa11;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-yellowcab,
.swatch-circle-yellowcab.large-swatch:after {
  background-color: #ffcd00; }

.swatch-square-yellowcab {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.375em;
  height: 1.375em;
  background: #ffcd00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-yellowcab.disabled {
    opacity: 0.2; }

.swatch-filter-yellowcab {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffcd00;
  border-radius: 0.69em;
  background-color: #ffcd00;
  display: block;
  position: relative; }
  .swatch-filter-yellowcab.disabled {
    opacity: 0.2; }

.swatch-circle-clear.large-swatch:after,
.product-tile__wrapper .swatch-circle-clear {
  background: linear-gradient(135deg, #dedede 0%, #fefefe 52%, #fefefe 69%, #f4f4f4 100%); }

.product-tile__wrapper .swatch-circle-blackwhite {
  background: linear-gradient(135deg, #000000 0%, #000000 50%, #ffffff 51%, #ffffff 100%);
  border: 1px solid #ccc; }

.css-swatch-circle {
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 100%;
  border: none;
  display: block;
  position: relative; }
  .css-swatch-circle.large-swatch {
    width: 3.25rem;
    height: 3.25rem;
    border: none;
    background-color: white; }
    .css-swatch-circle.large-swatch:after {
      content: '';
      display: block;
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .css-swatch-circle.swatch-circle-white.large-swatch {
    background-color: #CCD1D6; }
  .css-swatch-circle.swatch-circle-clear.large-swatch {
    background-color: #F5F5F5; }
  .css-swatch-circle.swatch-circle-blackwhite {
    border: 1px solid #ddd; }
  .css-swatch-circle.swatch-circle-blackwhite:after {
    background: linear-gradient(135deg, #000000 0%, #000000 50%, #ffffff 51%, #ffffff 100%); }

.swatch-circle-miscellaneous,
.swatch-square-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 1.375em;
  position: relative;
  transform: rotate(35deg);
  width: 1.375em; }
  .swatch-circle-miscellaneous.disabled,
  .swatch-square-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after,
  .swatch-square-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.swatch-square-miscellaneous {
  border-radius: 0;
  transform: unset; }
  .swatch-square-miscellaneous.selected::after {
    transform: unset; }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

.compare-page {
  margin-bottom: 3.75rem; }
  @media (min-width: 769px) {
    .compare-page {
      margin-bottom: 4.625rem; } }
  .compare-page__header {
    padding: 1.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-gray-table-row);
    position: relative; }
    @media (min-width: 769px) {
      .compare-page__header {
        padding: 2.25rem 0rem 2.5rem;
        flex-direction: column; } }
  .compare-page__heading {
    order: 0;
    margin-bottom: 0;
    font-size: 1.25rem; }
    @media (min-width: 769px) {
      .compare-page__heading {
        font-size: 3rem;
        margin-bottom: 1.75rem; } }
  .compare-page .product-tile-compare-row .no-quickadd {
    background-color: var(--color-primary);
    color: var(--color-white);
    text-decoration: none; }
    @media (min-width: 769px) {
      .compare-page .product-tile-compare-row .no-quickadd.btn {
        min-width: 179px;
        padding: 0.5625rem 0;
        line-height: 1.25rem; } }
    .compare-page .product-tile-compare-row .no-quickadd:hover {
      background-color: var(--color-accent);
      color: var(--color-black); }
    .compare-page .product-tile-compare-row .no-quickadd .quick-atc {
      color: var(--color-white);
      text-decoration: none; }
      .compare-page .product-tile-compare-row .no-quickadd .quick-atc:hover {
        background-color: var(--color-accent);
        color: var(--color-black); }
  .compare-page .product-tile-compare-row .no-quickadd.quick-atc {
    background-color: var(--color-primary);
    color: var(--color-white);
    text-decoration: none;
    min-width: 0;
    width: 100%; }
    .compare-page .product-tile-compare-row .no-quickadd.quick-atc:hover {
      background-color: var(--color-accent);
      color: var(--color-black); }
    @media (max-width: 991.98px) {
      .compare-page .product-tile-compare-row .no-quickadd.quick-atc {
        padding: 0.5625rem 0; } }
  .compare-page .product-tile-compare-row .custom-control {
    width: 100%;
    padding: 0; }
  .compare-page .product-tile-compare-row .quickadd {
    color: white;
    background-color: black;
    border-radius: 100px;
    text-decoration: none;
    padding: 0.5625rem 0;
    width: 100%; }
    .compare-page .product-tile-compare-row .quickadd:hover {
      color: black;
      background-color: var(--color-green); }
  .compare-page__back, .compare-page__save {
    order: 1; }
    @media (min-width: 769px) {
      .compare-page__back, .compare-page__save {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); } }
    .compare-page__back.btn, .compare-page__save.btn {
      padding: 0; }
    @media (min-width: 769px) {
      .compare-page__back, .compare-page__save {
        order: 0; } }
  .compare-page__back {
    left: 20px; }
    .compare-page__back.btn-text {
      font-size: 0.75rem; }
    @media (min-width: 769px) {
      .compare-page__back {
        position: relative;
        text-align: left;
        left: 0; }
        .compare-page__back.btn-text {
          font-size: 1rem; } }
  .compare-page__save {
    right: 20px; }
  .compare-page #quickAddModal .modal.fade .modal-dialog, .compare-page #quickAddModalCompare .modal.fade .modal-dialog {
    transform: translate(0, -20px); }
  .compare-page .quick-atc {
    min-width: auto; }

.compare-table {
  transition: all 0.4s ease-out; }
  .compare-table.sticky-top {
    position: sticky;
    top: 138px;
    z-index: 2; }
  .compare-table.isSticky .product-badge {
    display: none; }
  .compare-table.isSticky .compare-table__cell {
    padding: 0 0.625rem; }
  .compare-table.isSticky .product-tile {
    flex-direction: row;
    background-color: transparent;
    margin-top: 0;
    min-height: 0; }
  .compare-table.isSticky .product-tile__wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto; }
  .compare-table.isSticky .product-tile__picture {
    flex: 0 0 65px;
    overflow: hidden; }
    .compare-table.isSticky .product-tile__picture .quickadd, .compare-table.isSticky .product-tile__picture .quick-atc {
      display: none; }
  .compare-table.isSticky .product-tile__body {
    text-align: left;
    flex: 0 0 70%;
    display: flex;
    flex-direction: column; }
  .compare-table.isSticky .product-tile__name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis; }
  .compare-table.m-highlight-compare .m-common-characteristic .compare-table__cell {
    background: #f4f4f4; }
  .compare-table.compare-characteristics .compare-table__row > .compare-table__cell {
    border-right: 1px solid #979797;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797; }
    .compare-table.compare-characteristics .compare-table__row > .compare-table__cell.compare-table__cell-heading {
      border-top: none;
      border-bottom: none;
      border-right: none; }
    .compare-table.compare-characteristics .compare-table__row > .compare-table__cell:last-child {
      border-right: none; }
  .compare-table.compare-characteristics .compare-table__row:last-child > .compare-table__cell {
    border-bottom: 1px solid #979797; }
  .compare-table.compare-characteristics .compare-table__row .compare-table__cell:not(.compare-table__cell-heading) {
    font-size: 0.875rem; }
  @media (min-width: 769px) {
    .compare-table.compare-characteristics .compare-table__row .compare-table__cell:not(.compare-table__cell-heading) {
      font-size: 0.875rem; } }
  .compare-table.compare-tile {
    padding: 0.625rem 0 0.9375rem;
    background-color: #f4f4f4;
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
    @media (min-width: 769px) {
      .compare-table.compare-tile {
        padding: 1.25rem 0 1.875rem; }
        .compare-table.compare-tile.isSticky {
          padding: 1rem 0; } }
    @media (min-width: 1440px) {
      .compare-table.compare-tile {
        margin-left: 0rem;
        margin-right: 0rem; } }
    .compare-table.compare-tile .compare-table__body {
      border: none;
      overflow: visible; }
    .compare-table.compare-tile .compare-table__row {
      overflow: visible; }
  .compare-table.m-width-3 .compare-table__row {
    width: 150%; }
  .compare-table.m-width-3 .compare-table__cell {
    max-width: 33.33%; }
  .compare-table.m-width-4 .compare-table__row {
    width: 200%; }
  .compare-table.m-width-4 .compare-table__cell {
    max-width: 25%; }
  @media (min-width: 992px) {
    .compare-table.m-width-3 .compare-table__row, .compare-table.m-width-4 .compare-table__row {
      width: 100%; }
    .compare-table.m-width-3 .compare-table__cell, .compare-table.m-width-4 .compare-table__cell {
      max-width: 25%; } }
  @media (min-width: 992px) {
    .compare-table__buttons {
      display: none; } }
  .compare-table__body {
    overflow: hidden;
    border: 0; }
    @media (min-width: 769px) {
      .compare-table__body {
        overflow: auto; } }
  .compare-table__row {
    display: flex;
    justify-content: center;
    transition: 0.3s ease; }
    @media (min-width: 992px) {
      .compare-table__row {
        transform: none !important; } }
    .compare-table__row.m-hidden-row {
      display: none; }
  .compare-table__cell {
    flex: 0 1 100%;
    padding: 0.875rem 0.3125rem;
    position: relative;
    transition: 0.3s ease;
    font-size: 0.875rem; }
    @media (min-width: 769px) {
      .compare-table__cell {
        max-width: 25%;
        font-size: 1rem; } }
    .compare-table__cell:not(.compare-table__cell-heading) {
      background: var(--color-white);
      text-align: center; }
    .compare-table__cell.product-header {
      padding: 0 0.625rem;
      background: var(--color-grey);
      display: flex; }
      @media (min-width: 769px) {
        .compare-table__cell.product-header {
          padding: 0 1.375rem; } }
      .compare-table__cell.product-header .product-tile__body {
        padding: 0.75rem 0.75rem 0;
        background: var(--color-grey); }
      .compare-table__cell.product-header .product-tile__wrapper {
        width: 95%; }
    .compare-table__cell .close-button {
      position: static;
      top: 1.25rem;
      right: 0.625rem;
      z-index: 5;
      padding-left: 0.25rem; }
      @media (min-width: 769px) {
        .compare-table__cell .close-button {
          right: 1.5rem; } }
    .compare-table__cell .close {
      opacity: 1; }
      .compare-table__cell .close::before {
        width: 0.625rem;
        height: 0.625rem;
        background-size: cover; }
    .compare-table__cell-heading {
      padding-left: 0.5rem;
      font-family: "SuisseIntl-SemiBold", sans-serif;
      font-size: 0.875rem; }
      @media (min-width: 769px) {
        .compare-table__cell-heading {
          padding: 0.75rem 0.3125rem 0.5rem;
          font-size: 0.875rem; } }
      .compare-table__cell-heading:last-child {
        display: none; }
  .compare-table__charact-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 2rem 1.25rem 2rem;
    position: relative;
    margin-bottom: 0rem; }
    @media (min-width: 769px) {
      .compare-table__charact-header {
        padding: 2rem 0.3125rem 2.4375rem;
        justify-content: left;
        margin-bottom: 0rem; } }
  .compare-table__switcher {
    display: none; }
    .compare-table__switcher .form-group {
      margin: 0; }
  .compare-table__heading {
    margin: 0;
    display: none; }
    @media (min-width: 769px) {
      .compare-table__heading {
        display: block; } }
  .compare-table .product-tile {
    margin-top: 0; }
  .compare-table .product-tile .product-tile-compare-row {
    max-width: 100%; }

.compare-view-more {
  display: flex;
  justify-content: center;
  margin: 2.0625rem 0; }
  .compare-view-more__btn {
    font-size: 0.75rem; }
    @media (min-width: 769px) {
      .compare-view-more__btn {
        font-size: 1rem; } }
    .compare-view-more__btn .less-label {
      display: none; }
    .compare-view-more__btn.m-active .less-label {
      display: block; }
    .compare-view-more__btn.m-active .more-label {
      display: none; }

.hidden-rows {
  display: none; }

.btn.compare-arrow {
  position: absolute;
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate(0, -50%);
  transition: 0.3s ease;
  background: var(--color-white);
  min-width: auto; }
  .btn.compare-arrow.m-prev {
    left: 0.9375rem; }
  .btn.compare-arrow.m-next {
    right: 0.9375rem; }

.compare-support {
  padding: 2rem 1.25rem 2rem;
  margin-bottom: 3.4375rem; }
  @media (min-width: 769px) {
    .compare-support {
      padding: 2.625rem 1.25rem 2.3125rem;
      margin-bottom: 4.3125rem; } }
  .compare-support .content-asset {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (min-width: 769px) {
      .compare-support .content-asset {
        flex-direction: row; } }
    .compare-support .content-asset p {
      margin: 0 0.3125rem; }
      .compare-support .content-asset p a {
        color: var(--color-blue);
        font-family: "SuisseIntl-Medium", sans-serif;
        font-display: swap; }

.compare-bar-wrapper {
  text-align: center;
  padding: 1.0625rem 0.8125rem 0.75rem; }
  @media (min-width: 769px) {
    .compare-bar-wrapper {
      padding: 0 0.9375rem; } }
  .compare-bar-wrapper.hidden {
    bottom: -15.9375rem; }
    .compare-bar-wrapper.hidden .selected-product {
      max-width: 11.9375rem; }
      .compare-bar-wrapper.hidden .selected-product__tile .close::before {
        width: 0.75rem;
        height: 0.75rem; }
      .compare-bar-wrapper.hidden .selected-product__image {
        margin: 0; }
      .compare-bar-wrapper.hidden .selected-product__family, .compare-bar-wrapper.hidden .selected-product__name, .compare-bar-wrapper.hidden .selected-product__price {
        display: none; }
  .compare-bar-wrapper .compare-bar {
    padding: 0 0 1rem; }
    @media (min-width: 769px) {
      .compare-bar-wrapper .compare-bar {
        padding: 3rem 0.9375rem 3rem; } }
    .compare-bar-wrapper .compare-bar .btn:disabled {
      background-color: transparent; }
  .compare-bar-wrapper .toggle-compare {
    display: none; }
    @media (min-width: 992px) {
      .compare-bar-wrapper .toggle-compare {
        top: 0;
        right: 75%;
        transform: translate(20%, -100%);
        display: block;
        width: 228px;
        text-align: left; } }
    .compare-bar-wrapper .toggle-compare .btn-link {
      color: var(--color-white);
      padding: 0.75rem;
      min-width: 9.375rem;
      font-size: 0.875rem; }
      .compare-bar-wrapper .toggle-compare .btn-link:hover, .compare-bar-wrapper .toggle-compare .btn-link:active, .compare-bar-wrapper .toggle-compare .btn-link:focus {
        text-decoration: none; }
      .compare-bar-wrapper .toggle-compare .btn-link .fa {
        float: right;
        padding: 0.5rem 0 0 4.625rem; }
  .compare-bar-wrapper .product-slots {
    flex-basis: 100%;
    height: auto;
    order: 2; }
    .compare-bar-wrapper .product-slots__wrapper {
      position: relative;
      display: flex;
      flex-direction: column; }
      @media (min-width: 769px) {
        .compare-bar-wrapper .product-slots__wrapper {
          display: flex;
          padding: 0 8.75rem; } }
  .compare-bar-wrapper .action {
    justify-content: flex-start;
    padding: 0 0.625rem 1rem;
    margin-top: 5px;
    order: 1;
    flex-direction: row;
    align-items: flex-start; }
    @media (min-width: 769px) {
      .compare-bar-wrapper .action {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        flex-direction: column;
        padding: 0 0.625rem; } }
    .compare-bar-wrapper .action .btn {
      min-width: auto;
      text-decoration: underline;
      padding: 0;
      color: var(--color-black);
      font-family: "SuisseIntl-Medium", sans-serif;
      font-display: swap; }
      @media (max-width: 768.98px) {
        .compare-bar-wrapper .action .btn {
          margin-top: 0;
          width: 30%;
          text-align: left; } }
      .compare-bar-wrapper .action .btn[disabled] {
        opacity: 0.5; }
      .compare-bar-wrapper .action .btn.compare {
        font-size: 0.75rem;
        text-decoration: underline;
        font-family: "SuisseIntl-SemiBold", sans-serif;
        font-display: swap; }
        @media (min-width: 769px) {
          .compare-bar-wrapper .action .btn.compare {
            font-size: 1rem;
            padding-bottom: 0.875rem; } }
    .compare-bar-wrapper .action .clear-all {
      margin-top: 0;
      font-size: 0.75rem;
      font-family: "SuisseIntl-SemiBold", sans-serif;
      font-display: swap; }
      @media (min-width: 769px) {
        .compare-bar-wrapper .action .clear-all {
          display: block;
          text-align: left;
          margin: 0;
          font-size: 1rem; } }
  .compare-bar-wrapper .slot {
    background: none; }
    .compare-bar-wrapper .slot .close {
      float: none;
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
      background: none;
      width: auto;
      height: auto; }
      .compare-bar-wrapper .slot .close::before {
        width: 0.9375rem;
        height: 0.9375rem; }
      .compare-bar-wrapper .slot .close::after {
        content: '';
        width: 1.125rem;
        height: 1.125rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .compare-bar-wrapper .slot .close .fa {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translate(0, -50%); }
  .compare-bar-wrapper .selected-product {
    flex-basis: 25%;
    max-width: 18.125rem;
    text-align: left;
    padding: 0 1.125rem;
    transition: 0.3s ease; }
    .compare-bar-wrapper .selected-product__tile {
      position: relative;
      width: 100%;
      height: auto; }
    .compare-bar-wrapper .selected-product__image {
      background: var(--color-white);
      position: relative;
      padding-bottom: 100%;
      margin-bottom: 1.625rem; }
      .compare-bar-wrapper .selected-product__image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .compare-bar-wrapper .selected-product__family {
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-bottom: 0.5rem;
      font-family: "SuisseIntl-SemiBold", sans-serif;
      font-display: swap; }
    .compare-bar-wrapper .selected-product__name {
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin-bottom: 0.75rem; }
    .compare-bar-wrapper .selected-product__price {
      font-size: 1rem;
      line-height: 1.375rem;
      font-family: "SuisseIntl-Regular", sans-serif;
      font-display: swap; }
  @media (max-width: 991.98px) {
    .compare-bar-wrapper .selected-product .close, .compare-bar-wrapper.hidden .selected-product .close {
      top: 0.375rem;
      right: 0.375rem; }
      .compare-bar-wrapper .selected-product .close::before, .compare-bar-wrapper.hidden .selected-product .close::before {
        width: 0.4375rem;
        height: 0.4375rem;
        background-size: 0.4375rem; }
    .compare-bar-wrapper .selected-product__image, .compare-bar-wrapper.hidden .selected-product__image {
      margin-bottom: 0; }
    .compare-bar-wrapper .selected-product__family, .compare-bar-wrapper .selected-product__name, .compare-bar-wrapper .selected-product__price, .compare-bar-wrapper.hidden .selected-product__family, .compare-bar-wrapper.hidden .selected-product__name, .compare-bar-wrapper.hidden .selected-product__price {
      display: none; } }
  @media (max-width: 768.98px) {
    .compare-bar-wrapper .selected-product {
      max-width: 7.5rem;
      padding: 0 0.875rem;
      flex-basis: 100%; } }

.breadcrumbs {
  margin-bottom: 0; }

.search-results .no-results {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.search-nav {
  border-bottom: 1px solid var(--color-grey2); }
  .search-nav .nav-tabs .nav-item {
    width: 50%; }
    @media (min-width: 544px) {
      .search-nav .nav-tabs .nav-item {
        width: auto; } }

.search-results .tab-content {
  padding: 0; }
  @media (max-width: 768.98px) {
    .search-results .tab-content {
      padding-left: 15px;
      padding-right: 15px; } }

.search-results .grid-header {
  background-color: #F4F4F4;
  margin: 0;
  padding-top: 2em;
  padding-bottom: 2em; }
  @media (min-width: 769px) {
    .search-results .grid-header .btn {
      min-width: 12.75rem; } }

.search-results .show-more .product-count {
  margin-bottom: 0.75rem; }

.search-results .category-base-url {
  margin-top: 0.75rem; }

@media (min-width: 769px) {
  .grid-header {
    position: relative;
    justify-content: space-between;
    align-items: center; } }

.grid-header .result-count {
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .grid-header .result-count {
      position: absolute;
      left: 22.86054%; } }
  .grid-header .result-count p {
    margin: 0;
    font-size: 1.125rem; }
    @media (max-width: 991.98px) {
      .grid-header .result-count p {
        text-align: center; } }

.grid-header .filter-results {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    .grid-header .filter-results {
      font-size: 1rem; } }
  .grid-header .filter-results:hover {
    text-decoration: none; }
  .grid-header .filter-results:before {
    content: none;
    display: block;
    background-image: url("../../images/icons/filter.svg");
    background-size: 0.75rem;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.875rem;
    width: 1.875rem; }
  .grid-header .filter-results .show-filters {
    display: block; }
  .grid-header .filter-results .hide-filters {
    display: none; }
  .grid-header .filter-results.js-showing .show-filters {
    display: none; }
  .grid-header .filter-results.js-showing .hide-filters {
    display: block; }

.grid-header .grid-sort {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 768.98px) {
    .grid-header .grid-sort {
      height: auto;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.3s ease, opacity 0.3s ease; }
      .grid-header .grid-sort.js-show {
        max-height: 6.25rem;
        opacity: 1; } }
  .grid-header .grid-sort .sort-by-label {
    font-family: "SuisseIntl-SemiBold", sans-serif;
    font-display: swap;
    color: var(--skin-link-color-1);
    letter-spacing: 0.25px;
    margin-right: 0.75rem;
    font-size: 0.875rem; }
    @media (min-width: 992px) {
      .grid-header .grid-sort .sort-by-label {
        font-size: 1rem; } }
    @media (max-width: 768.98px) {
      .grid-header .grid-sort .sort-by-label {
        display: none; } }
  .grid-header .grid-sort .sort-order {
    width: 100%; }
    @media (min-width: 769px) {
      .grid-header .grid-sort .sort-order {
        max-width: 10.75rem; } }
    @media (max-width: 768.98px) {
      .grid-header .grid-sort .sort-order {
        margin-top: 1rem; } }

.grid-header .mobile-sort-trigger {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .grid-header .mobile-sort-trigger .sort-by-label {
    text-decoration: none;
    font-family: "SuisseIntl-SemiBold", sans-serif;
    font-display: swap;
    color: var(--skin-link-color-1);
    letter-spacing: 0.25px;
    margin-right: 0.75rem;
    font-size: 0.875rem;
    padding: 0;
    display: flex;
    align-items: center;
    height: 3.125rem; }
    @media (min-width: 992px) {
      .grid-header .mobile-sort-trigger .sort-by-label {
        font-size: 1rem; } }
    @media (max-width: 768.98px) {
      .grid-header .mobile-sort-trigger .sort-by-label {
        margin-right: 0;
        justify-content: center;
        color: var(--color-white); } }
    .grid-header .mobile-sort-trigger .sort-by-label:after {
      content: '';
      display: block;
      background-image: url("../images/icons/caret-down.svg");
      background-size: 0.75rem;
      background-repeat: no-repeat;
      background-position: center;
      height: 1.875rem;
      width: 1.875rem; }
      @media (max-width: 768.98px) {
        .grid-header .mobile-sort-trigger .sort-by-label:after {
          background: none;
          content: none; } }

.grid-header .filter-bar {
  margin: 0; }
  .grid-header .filter-bar ul {
    margin-top: 0.875rem; }
    @media (min-width: 992px) {
      .grid-header .filter-bar ul {
        margin-top: 0; } }

.result-count {
  font-size: 1rem; }

.refinement a {
  cursor: pointer; }

@media (min-width: 992px) {
  .grid-main {
    position: relative; } }

.refinement-bar {
  padding: 0;
  box-shadow: none;
  display: block; }
  .refinement-bar span {
    font-size: 0.875rem;
    line-height: 1.5rem; }
  @media (max-width: 991.98px) {
    .refinement-bar {
      background-color: var(--color-white);
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: auto;
      max-height: 100vh;
      z-index: 5;
      transition: left 0.3s ease; } }
  @media (min-width: 992px) {
    .refinement-bar {
      display: block !important;
      max-width: 22.86054%;
      position: static;
      top: 0;
      animation: refinements-out 0.3s forwards;
      margin-top: 0.625rem;
      margin-bottom: 5rem; } }
  @media (max-width: 991.98px) {
    .refinement-bar.show {
      left: 0; } }
  @media (min-width: 769px) {
    .refinement-bar.show {
      padding-left: 1.25rem; } }
  @media (min-width: 992px) {
    .refinement-bar.show {
      animation: refinements-in 0.3s forwards; } }
  .refinement-bar .refinement-bar-inner {
    padding: 0 15px; }
  .refinement-bar .secondary-bar {
    padding: 0; }
    @media (min-width: 769px) {
      .refinement-bar .secondary-bar .btn {
        min-width: 17.75rem; } }
  .refinement-bar .result-count {
    font-family: "SuisseIntl-Medium", sans-serif;
    font-display: swap;
    font-size: 0.75rem;
    color: var(--color-grey5);
    margin-bottom: 0.5rem; }

.filter-header {
  margin: 0; }
  .filter-header .header-bar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    background: none;
    border: 0; }
    @media (max-width: 543.98px) {
      .filter-header .header-bar {
        padding: 3rem 0 1rem; } }
  .filter-header__left {
    display: flex;
    align-items: baseline;
    align-self: flex-end; }
    .filter-header__left .result-count {
      margin: 0 0 0 2.5rem; }
  .filter-header .filters-headline {
    font-family: "SuisseIntl-SemiBold", sans-serif;
    font-display: swap;
    margin: 0;
    font-size: 1.25rem; }
  .filter-header .close:before {
    background-size: 0.8125rem; }

.filter-bar {
  margin: 0 0 1.25rem 0; }
  @media (min-width: 992px) {
    .filter-bar {
      margin: 0; } }
  .filter-bar li {
    margin: 0 0.625rem 0.5rem 0; }
    .filter-bar li.filter-value {
      padding: 0;
      border: 0;
      background-color: var(--color-grey12);
      border-radius: 0; }
      .filter-bar li.filter-value button {
        font-family: "SuisseIntl-Medium", sans-serif;
        font-display: swap;
        font-size: 1rem;
        padding: 0.5rem 2.125rem 0.5rem 0.625rem;
        min-width: 0;
        position: relative; }
        .filter-bar li.filter-value button:after {
          content: '';
          display: block;
          background-image: url("../../images/icons/close.svg");
          background-size: 0.5625rem;
          background-repeat: no-repeat;
          background-position: center;
          height: 1.875rem;
          width: 1.875rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }

.reset.clear-filters {
  font-family: "SuisseIntl-Medium", sans-serif;
  font-display: swap;
  font-size: 1rem;
  text-decoration: underline; }

.refinements {
  border-top: 1px solid var(--color-black);
  margin: 0;
  padding: 0; }
  .refinements .radio-selector {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; }
    .refinements .radio-selector:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      border: 0.125rem solid var(--color-black);
      background: transparent;
      border-radius: 50%;
      pointer-events: none;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .refinements .radio-selector:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      background: no-repeat 50% / 50% 50%; }
    .refinements .radio-selector.selected:before {
      color: var(--color-white);
      border-color: var(--skin-primary-color-1);
      background-color: var(--skin-primary-color-1); }
    .refinements .radio-selector.selected:after {
      background-size: 0.375rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .refinements .checkbox-selector {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem; }
    .refinements .checkbox-selector:before {
      content: '';
      position: absolute;
      display: block;
      border: 0.125rem solid var(--color-black);
      background: transparent;
      width: 1.25rem;
      height: 1.25rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0;
      pointer-events: none;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .refinements .checkbox-selector:after {
      content: '';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-white);
      background: no-repeat 50% / 50% 50%; }
    .refinements .checkbox-selector.selected:before {
      background-color: var(--color-black);
      border-color: var(--color-black); }
    .refinements .checkbox-selector.selected:after {
      background-image: url("../../images/icons/check-white.svg");
      background-repeat: no-repeat;
      background-size: 1.25rem; }
  .refinements .refinement-categories ul li li {
    padding-left: 1rem; }
  .refinements .refinement-categories span.selected {
    font-family: "SuisseIntl-SemiBold", sans-serif; }
  .refinements .checkbox-label {
    text-align: left; }

.refinement.card.refinement {
  border-bottom: 1px solid var(--color-black);
  margin: 0;
  padding-bottom: 1.1875rem;
  border-radius: 0; }

.refinement.card .card-header {
  border: 0; }
  .refinement.card .card-header .title {
    color: var(--color-black);
    position: relative;
    width: 100%;
    border: 0;
    padding: 0.875rem 0 0; }
    .refinement.card .card-header .title[aria-expanded="true"]:after {
      content: '';
      background-image: url("../../images/icons/minus.svg");
      background-repeat: no-repeat;
      background-size: 0.75rem;
      background-position: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-27%);
      display: block;
      height: 1.875rem;
      width: 1.875rem; }
    .refinement.card .card-header .title[aria-expanded="false"]:after {
      content: '';
      background-image: url("../../images/icons/plus.svg");
      background-repeat: no-repeat;
      background-size: 1rem;
      background-position: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-27%);
      display: block;
      height: 1.875rem;
      width: 1.875rem; }

.refinement.card .card-body {
  padding: 1rem 0 0; }

.refinement.card .refinement-headline {
  font-family: "SuisseIntl-SemiBold", sans-serif;
  font-display: swap;
  font-size: 1rem;
  font-weight: normal;
  text-transform: none; }

.refinement.card .values .attr-value:not(:last-child) {
  margin-bottom: 0.3125rem; }

.refinement-availability .custom-checkbox {
  margin: 0;
  display: flex;
  align-items: center; }

.refinements-footer {
  padding: 3rem 0 8.75rem;
  display: flex;
  justify-content: center; }
  .refinements-footer button {
    margin: 0 0.3125rem 1.5625rem; }

.grid-wrapper {
  width: 100%;
  transition: max-width 0.3s ease, margin-left 0.3s ease; }
  @media (min-width: 992px) {
    .refinements-active .grid-wrapper {
      max-width: 77.13946%; } }

.grid-footer {
  margin: 1.875rem 0 3.125rem; }
  @media (min-width: 992px) {
    .grid-footer {
      margin: 2.5rem 0 5rem; } }
